import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translations_en from "./translations/en.json";
import translations_nl from "./translations/nl.json";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(backend)
    .init({
        keySeparator: false,
        resources: {
            en: {
                common: translations_en
            },
            nl: {
                common: translations_nl
            },
        },
        supportedLngs: ["en", "nl"],
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        fallbackLng: 'en',
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
        },
        ns: ['common'],
        defaultNS: 'common',
    });
