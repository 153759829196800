import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {CartRow} from "./cartRow";
import {OrderItemFormatted} from "../../../domain/models/cartApiResponseModel";

export function CartTable(props: { items: OrderItemFormatted[] }) {
    const {items} = props;
    const {t} = useTranslation('common');

    const headers = useMemo(() => [
        {
            text: '',
            className: 'cart-th-image',
        },
        {
            text: t('Description'),
            className: 'cart-th-desc',
        },
        {
            text: t('Qty'),
            className: 'cart-th-qty',
        },
        {
            text: t('Price'),
            className: 'cart-th-price text-end',
        },
        {
            text: t('Total'),
            className: 'cart-th-total text-end',
        },
    ], [t]);

    return (
        <Row className={'cart m-0'}>
            <Col>
                <div className="table-responsive">
                    <table className={'w-100'}>
                        <thead>
                        <tr className={'cart-table-header'}>
                            {headers.map((header, delta) => <th key={delta} className={header.className}>
                                {header.text}
                            </th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, index) => (
                            <CartRow item={item} key={index}/>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
);
}

