import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {resetPassword, resetPasswordReset} from "../../infrastructure/slices/resetPasswordSlice/resetPasswordSlice";
import {Link} from "react-router-dom";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import i18next from "i18next";

export function PasswordForgottenForm() {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');

    const { error, mailed } = useAppSelector(state => state.resetPassword)

    useEffect(() => {
        dispatch(resetPasswordReset());
        setEmail('');
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        dispatch(resetPassword(email))
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row className={'login-card p-2 py-4 mb-3'}>
                    <h4 className={'py-3'}>{t('Forgotten password')}</h4>
                    { error && (
                        <Alert key={'danger'} variant={'danger'}>
                            {t('Something went wrong, please try again.')}
                        </Alert>
                    )}
                    <div className={'t-center'}>
                        { mailed ? (
                            <p className={'mt-0'}>
                                {t('Password reset instructions will be mailed to')} &nbsp;
                                <a href={'mailto:' + email}>{email}.</a> &nbsp;
                                {t('You must log out to use the password reset link in the email.')}
                            </p>
                        ) : (
                            <p className={'mt-0'}>
                                {t('If you have forgotten your password, enter your email and click - Send - to receive instructions on how to reset it.')}
                            </p>
                        )}
                    </div>
                    { !mailed &&
                        <Form.Group className={'mb-3'} controlId={'loginForm.ControlEmail'}>
                            <Form.Label className={'m-0'}>{t('Email')}</Form.Label>
                            <Form.Control
                                type={'text'}
                                required
                                onChange={(event) => setEmail(event.target.value)}
                                placeholder={t('Fill in your emailaddres')}
                            />
                        </Form.Group>
                    }
                </Row>
                <Row xs={'auto'} className={'align-items-center'}>
                    { !mailed &&
                        <Col className={'ps-0'}>
                            <Button variant={'cb-red'} type={'submit'} disabled={!email}>
                                {t('Send')}
                            </Button>
                        </Col>
                    }
                    <Col>
                        <Link to={`/${i18next.language}/user/login`}>{t('Back to login')}</Link>
                    </Col>
                </Row>
            </Form>
        </>
    );
}