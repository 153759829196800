import {useTranslation} from "react-i18next";
import React from "react";
import {useAppSelector} from "../../../../../hooks/useTypedSelector";
import default150 from "../../../../product/modules/commons/images/product/default-150.png";
import {OrderItemFormatted} from "../../../../cart/domain/models/cartApiResponseModel";
import {Loading} from "../../../../general/features/loading";

function CartRow(props: { item: OrderItemFormatted }) {
    const {item} = props;
    const {t} = useTranslation('common');

    const { cartLoaded} = useAppSelector((state) => state.cart);

    return <>
        <tr className={'cart-table-row'}>
            {!cartLoaded ? (<td colSpan={9999} className={'p-rel'}><Loading/></td>) : (<>
                <td>
                    <img loading="lazy" decoding="async"
                        src={item.product_images.length > 0 ? item.product_images[0] : default150}
                        alt={item.name}
                        width={'100'}/>
                </td>
                <td className={'d-flex flex-column justify-content-between'}>
                    <div>
                        <h6>{item.name}</h6>
                        <span>{t('Article number')} {item.sku}</span><br />
                        <span>{t('Qty')}: {item.quantity}</span>
                    </div>

                </td>
                <td className={'text-end'}>
                    {item.price}
                </td>
            </>)}
        </tr>
        <tr>
            <td colSpan={99}>
                <hr/>
            </td>
        </tr>
    </>
}

export function CartTable(props: { items: OrderItemFormatted[] }) {
    const {items} = props;

    return (
        <div className="table-responsive">
            <table className={'w-100'}>
                <tbody>
                {items.map((item, index) => (
                    <CartRow item={item} key={index}/>
                ))}
                </tbody>
            </table>
        </div>
    );
}

