import React, {ReactElement} from "react";
import { Card} from "react-bootstrap";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { BsFileText } from "react-icons/bs";
import { VscAccount } from "react-icons/vsc";
import { BsChatRightDots } from "react-icons/bs";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {AppDispatch} from "../../../../store";
import i18next, {TFunction} from "i18next";
import {userLogout} from "../../../login/infrastructure/slices/userSlice/userSlice";
import {GoArrowSwitch} from "react-icons/go";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import {resetCurrentUserStorage} from "../../../login/infrastructure/slices/currentUserSlice/currentUserSlice";

type MenuItem = {
    icon: ReactElement|(() => ReactElement),
    title: string,
    href?: string,
    external?: boolean,
    onClick?: () => void
};

export const MenuItems: (dispatch: AppDispatch, t: TFunction, lang: string, masq: boolean) => MenuItem[] = (dispatch: AppDispatch, t: TFunction, lang: string, masq: boolean) => {
  const items: MenuItem[] = [
    {
      icon: <HiOutlineShoppingBag className="accountMenuIcons"/>,
      title: t('Orders'),
      href: `/${lang}/account/orders`,
    },
    {
      icon: <BsFileText className="accountMenuIcons"/>,
      title: t('Orderlist'),
      href: `/${lang}/assortment/order-list`
    },
    {
      icon: <VscAccount className="accountMenuIcons"/>,
      title: t('My account'),
      href: `/${lang}/account`
    },
    {
      icon: <BsChatRightDots className="accountMenuIcons"/>,
      title: t('Ask a question'),
      href: `https://www.cornybakers.nl/${lang}/contact/`,
      external: true
    },
    {
      icon: <RiLogoutBoxRLine className="accountMenuIcons"/>,
      title: t('Logout'),
      onClick: () => logout(dispatch)
    }
  ];

  if (masq) {
    const lang = i18next.language;
    const formatLang = lang === 'nl' ? '' : `/${lang}`;

    items.push({
      icon: <GoArrowSwitch className="accountMenuIcons"/>,
      title: t('Back to own account'),
      onClick: () => {
        return new Promise<void>(() => {
          resetCurrentUserStorage();

          location.href = `${CornyBakersEnv.API_BASE_URL}/${formatLang}/switch-back`;
        });
      }
    })
  }

  return items;
};

export const logout = async (dispatch: AppDispatch) => {
    await dispatch(userLogout());

    // Hard reload page to get clear storage.
    location.pathname = '/user/login';
}

const AccountMenu = () => {
    const {t} = useTranslation('common');
    const {lang} = useParams();

    const dispatch = useAppDispatch();

    const navigation = useNavigate();

    const {currentUser} = useAppSelector(state => state.currentUser)

    const currentLocation = location.pathname;

    const items = MenuItems(dispatch, t, lang ?? i18next.language, currentUser?.data.is_masquerading ?? false);

    return (
        <div className="accountMenu">
            <Card bg={"primary"} className={"product-categories"}>
                {items.map((item, delta) => <a key={delta} className={`account-menu-links ${currentLocation === item.href ? "active" : ""}`} onClick={() => {
                    if (item.onClick && typeof item.onClick === 'function') {
                        item.onClick();
                    }
                    else if (item.href) {
                        if (item.external) {
                            // Open external links in a new tab
                            window.open(item.href, '_blank');
                            return;
                        }

                        navigation(item.href);
                    }
                }}>
                    <>
                        {item.icon}
                        {item.title}
                    </>
                </a>)}
            </Card>
        </div>
    );
};

export default AccountMenu;
