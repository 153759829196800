import {ImageModel} from "../../../domain/models/imageModel";
import EmblaCarousel from "../../../../general/emblaCarousel/emblaCarousel";
import default150 from "../../commons/images/product/default-150.png";
import default307 from "../../commons/images/product/default-307.png";
import default640 from "../../commons/images/product/default-640.png";

export const defaultImages = [{
    id: 1,
    name: '',
    small_thumbnail: default150,
    thumbnail: default307,
    large: default640,
}];

export function ProductImage(props: { images: ImageModel[] }) {

    return (
        <>
            <div className={'product-image'}>
                <EmblaCarousel images={props.images.length > 0 ? props.images : defaultImages}/>
            </div>
        </>
    );
}
