import React from 'react';
import {useAppSelector} from "../../../../../hooks/useTypedSelector";
import {Loading} from "../../../../general/features/loading";
import {CartTable} from "./cartTable";
import {Totals} from "./totals";

export function CartOverview() {
    const {cart, loading} = useAppSelector((state) => state.cart);

    return <>
        {loading ? <Loading/> : (
            <>
                <CartTable items={cart?.items ?? []}/>
                <Totals />
            </>
        )
        }
    </>
}