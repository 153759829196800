import React, {BaseSyntheticEvent} from 'react';
import {Form} from "react-bootstrap";

interface CheckboxProps {
    label: string,
    onChange?: (newValue: boolean) => void,
    inputProps?: object
}

export function Checkbox(props: CheckboxProps) {
    const {label, onChange, inputProps} = props;

    const handleChange = (event: BaseSyntheticEvent) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    }

    return <Form.Group>
        <Form.Check
            type={'checkbox'}
            onChange={handleChange}
            label={label}
            {...inputProps}
        />
    </Form.Group>
}