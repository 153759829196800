import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios, {AxiosError} from "axios";
import {OrderModel, OrderSliceModel} from "../../domain/models/orderSliceModel";
import {RootState} from "../../../../store";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export const itemsPerPage = 15;

const initialState: OrderSliceModel = {
    loading: false,
    loaded: false,
    error: null,
    orders: [],
    page: 1,
    totalItems: 0,
};

export const getOrders = createAsyncThunk(
    "ordersSlice/getOrders",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const user = state.currentUser?.currentUser?.id;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const page = state.orders.page;

        const skip = page <= 1 ? 0 : (page - 1) * itemsPerPage;

        const url = API_BASE_URL + `${formatLang}/jsonapi/commerce_order/default?filter[uid.meta.drupal_internal__target_id]=${user}&filter[cart]=0&sort[placed][path]=placed&sort[placed][direction]=DESC&page[offset]=${skip}&page[limit]=${itemsPerPage}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return {
                orders: response.data.data as OrderModel[],
                totalItems: response.data.meta.count,
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

const ordersSlice = createSlice({
    name: 'ordersSlice',
    initialState,
    reducers: {
        // Reducers can be added here if needed in the future
        setPage: (state, action) => {
            state.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOrders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrders.rejected, (state, action) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload as string;
        });
        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.loaded = true;
            state.loading = false;
            state.orders = action.payload.orders;
            state.totalItems = action.payload.totalItems;
        });
    }
});

export const { setPage } = ordersSlice.actions;

export default ordersSlice.reducer;
