import {PageTitle} from "../../../page/features/PageTitle";
import {useTranslation} from "react-i18next";
import {DefaultPage} from "../../../page/features/default";
import {Col, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {refreshUpdate} from "../../infrastructure/slices/checkoutUpdateSlice";
import {Loading} from "../../../general/features/loading";


export function CheckoutUpdatePage() {
  const {t} = useTranslation('common');
  const {order, lang} = useParams();
  const dispatch = useAppDispatch();

  const navigation = useNavigate();

  const {loading, error, last_response} = useAppSelector(state => state.checkoutUpdate);

  useEffect(() => {
    if (order && (!last_response || (last_response.waiting ?? false))) {
      const reload = () => {
        dispatch(refreshUpdate({order: parseInt(order)}))
      }

      const id = setTimeout(reload, 2500);

      return () => {
        clearTimeout(id);
      }
    }
  }, [last_response]);

  useEffect(() => {
    if (last_response && last_response.status === 1) {
      navigation(`/${lang}/cart/checkout/${order}/completed`);
    }
    else if (last_response && last_response.status === 0 && !(last_response.waiting ?? false)) {
      // Cancelled. Redirect back to checkout URL.
      navigation(`/${lang}/cart/checkout`);
    }
  }, [last_response]);

  return (
    <DefaultPage className={"mt-5 checkout-completed"} showBreadCrumbs={false}>
      <PageTitle title={t('Status controleren')}/>
      <Row>
        <Col xs={12} className={'mb-5'}>
          {error && <p className={'text-danger'}>{error}</p>}
          {last_response && <p>{last_response.message}</p>}

          <div className={'w-100 p-rel'} style={{height: '150px'}}>
            {loading && <Loading />}
          </div>
        </Col>
      </Row>
    </DefaultPage>
  )
}