import {Col, Row} from "react-bootstrap";
import {DefaultPage} from "../../../page/features/default";
import {ReactNode} from "react";
import AccountMenu from "../../../content/modules/pages/AccountMenu";

export function BaseAccountPage(props: { children: ReactNode, pageTitle?: string, className?: string, replacements?: object, showBreadCrumbs?: boolean }) {
    const {children, className} = props;

    return (
        <DefaultPage className={`mt-5 account-page ${className}`} replacements={props.replacements} showBreadCrumbs={props.showBreadCrumbs}>
            <Row>
                <Col xs={{order: 1, span: 12}} md={{ order: 1, span: 3}}
                     className={'mb-3 d-none d-md-block'}>
                    <AccountMenu/>
                </Col>
                <Col xs={{order: 2, span: 12}} md={{ order: 1, span: 9}}
                     className={'mb-3'}>
                    <div className={'px-3'}>
                        {children}
                    </div>
                </Col>
            </Row>
        </DefaultPage>
    );
}

