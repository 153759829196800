import React, {useEffect} from "react";
import {BiCart, BiHeart} from "react-icons/bi";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";

import {getTotalFavoriteItems} from "../../../productFavorites/infrastructure/slices/productFavoriteSlice";
import {OrderItemFormatted} from "../../../cart/domain/models/cartApiResponseModel";

export function HeaderButtons() {
    const dispatch = useAppDispatch();
    const {totalItems} = useAppSelector((state) => state.productFavorite);
    const {cart} = useAppSelector((state) => state.cart);
    const items:OrderItemFormatted[] = cart?.items ?? [];
    const {lang} = useParams();

    useEffect(() => {
        dispatch(getTotalFavoriteItems());
    }, []);

    const cartQtyColor = items?.length > 0 ? 'cart-qty badge rounded-pill bg-danger' : 'cart-qty badge rounded-pill bg-secondary';
    const navigation = useNavigate();

    const location = useLocation();
    const showFavoritesFromUrl = location.pathname  === '/assortment/order-list';

    const toggleFavorites = () => {
        const toggle = !showFavoritesFromUrl;

        if (toggle) {
            navigation(`/${lang}/assortment/order-list`);
        } else {
            navigation(`${lang}/assortment`);
        }
    };

    const counter = items ? items.map(item => item.quantity).reduce((partialSum, a) => partialSum + a, 0) : 0;

    const gotoShoppingCart = () => {
        navigation(`/${lang}/cart`);
    }
    const productFavoriteQtyColor = totalItems > 0 ? 'cart-qty badge rounded-pill bg-warning' : 'cart-qty badge rounded-pill bg-secondary';

    return (
        <>
            <div className={'header-icon-wrapper'}>
                <BiHeart className={'header-icon link'}
                         onClick={toggleFavorites}/>
                <span className={productFavoriteQtyColor}>{totalItems}</span>
            </div>

            <div className={'header-icon-wrapper'}>
                <BiCart className={'header-icon link'}
                        onClick={gotoShoppingCart}/>
                <span className={cartQtyColor}>{counter}</span>
            </div>
        </>
    );
}
