import {useAppDispatch, useAppSelector} from "../../../../../hooks/useTypedSelector";
import {useEffect} from "react";
import {getRelationInfo} from "../../../infrastructure/slices/checkoutSlice";
import {Loading} from "../../../../general/features/loading";


export function Address() {

    const dispatch = useAppDispatch();

    const {relationData, relationError, relationLoading, relationLoaded} = useAppSelector((state) => state.checkout)
    const {currentUser, loading} = useAppSelector((state) => state.currentUser)

    useEffect(() => {
        if (!relationLoading) {
            dispatch(getRelationInfo());
        }
    }, [])

    if (relationError) {
        alert(relationError);
    }

    return <div className={'relation-data p-rel'}>
        {relationLoading && <Loading />}
        {relationLoaded && <>
        <p>
            {relationData.name} <br />
            {(!loading && currentUser) && `t.a.v. ${currentUser.name}`}
        </p>
        <p>
            {relationData.address?.address_line1} <br />
            {`${relationData.address?.postal_code} ${relationData.address?.locality}, ${relationData.address?.country_code}`}
        </p>
        <p>
            {relationData.phone} <br />
            {relationData.mail}
        </p>
        <p>
            {relationData.coc_number} <br />
            {relationData.vat_number}
        </p>
        </>}
    </div>
}