import React from "react";
import {Navigate} from "react-router-dom";
import {CurrentUserSliceDataModel} from "../../../root/login/infrastructure/slices/currentUserSlice/currentUserSliceModel";
import {Header} from "../../../root/header/modules/features/header";
import {Footer} from "../../../root/footer/modules/features/footer";

type Props = {
    children: JSX.Element
    user: CurrentUserSliceDataModel|undefined
}

export const ProtectedRoute = ({user, children} : Props): JSX.Element => {
    if (typeof user === "undefined") {
        return <Navigate to="/user/login" replace />;
    }

    return <>
        <Header/>
        {children}
        <Footer/>
    </>;
};