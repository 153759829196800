import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductRangeSliceModel} from "./productRangeSliceModel";
import {ProductModel, QuoteProductData,} from "../../../domain/models/productModel";
import axios, {AxiosError} from "axios";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import {ProductApiResponseModel} from "../../../domain/models/productApiResponseModel";
import i18next from "i18next";

import {RootState} from "../../../../../store";
import CategoryService from "../../../../header/infrastructure/services/categoryService";
import {Category} from "../../../../header/infrastructure/slices/menuSlice/menuSliceModel";
import {ImageModel} from "../../../domain/models/imageModel";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export const formatProduct = (product: ProductApiResponseModel): ProductModel => ({
    id: product.id,
    drupal_internal__product_id: product.drupal_internal__product_id,
    name: product.title,
    sku: (product.variations?.length ?? 0) > 0 ? product.variations[0]?.sku : '',
    description: product.body?.value,
    specifications: product?.cb_product?.product_specs?.value ?? '',
    quantity: 1,
    price: (product.variations?.length ?? 0) ? product.variations[0].price.formatted : '€ 0.00',
    images: (product?.images?.length ?? 0) > 0 ? product.images.map((image): ImageModel => ({
        id: parseInt(image.id),
        name: image.meta.alt + " - " + image.meta.title,
        small_thumbnail: image.image_style_uri.small_thumbnail,
        thumbnail: image.image_style_uri.thumbnail,
        large: image.image_style_uri.large,
    })) : [],
    quote_product_data: product.quote_product_data?.map((quoteProductItem) => ({
        id: quoteProductItem.id ? parseInt(quoteProductItem.id) : null,
        label: quoteProductItem.label,
        url: quoteProductItem.url,
        productData: quoteProductItem.productData.map((productDataItem) => ({
            target_id: productDataItem.target_id ? parseInt(productDataItem.target_id) : null,
            product_price_target_id: productDataItem.product_price_target_id ? parseInt(productDataItem.product_price_target_id) : null,
            standard_cost: productDataItem.standard_cost ? parseFloat(productDataItem.standard_cost) : null,
            quote_price: productDataItem.quote_price ? parseFloat(productDataItem.quote_price) : null,
            quote_price_formatted: productDataItem.quote_price_formatted,
            quantity: productDataItem.quantity ? parseInt(productDataItem.quantity) : null,
            markup: productDataItem.markup ? parseFloat(productDataItem.markup) : null,
            remark: productDataItem.remark ? parseFloat(productDataItem.remark) : null,
            unit_label: productDataItem.unit_label,
        })),
    } as QuoteProductData)) ?? [],
    is_favorite: product.is_favorite ? {
        favorite: product.is_favorite.type !== undefined,
        type: product.is_favorite.type as unknown as string,
        id: product.is_favorite.id as unknown as string,
        data: product.is_favorite.data,
    } : null,
    variations: {
        drupal_internal__variation_id: (product.variations?.length ?? 0) > 0 ? product.variations[0].drupal_internal__variation_id : 0,
        id: (product.variations?.length ?? 0) > 0 ? product.variations[0].id : '',
        product_id: {
            id: (product.variations?.length ?? 0) > 0 ? product.variations[0].product_id.id : 0,
        }
    },
    scaled_prices: ((product.variations?.length ?? 0) > 0 ? (product.variations[0].scaled_prices ?? []) : []).map(item => ({
        price: parseFloat(item.price),
        quantity: item.quantity,
    })),
}) as ProductModel;

export const getProducts = createAsyncThunk(
    "productRangeSlice/getProducts",
    async (filter: { fullText: string, is_favorite: boolean }, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;
        const page = state.productRange.page - 1;

        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const offset = page === 0 ? 0 : state.productRange.itemsPerPage * page;
        let url = `${API_BASE_URL}${formatLang}/jsonapi/index/products/?jsonapi_include=1&include=images,variations&filter[entity_type]=commerce_product&sort[artnr][path]=sku&sort[artnr][direction]=ASC&page[offset]=${offset}&page[limit]=${state.productRange.itemsPerPage}&page[u]=${state.currentUser.currentUser?.relation ?? ''}`;

        if (filter.fullText) {
            url += "&filter[fulltext]=" + filter.fullText;
        }

        if (filter.is_favorite && state?.currentUser?.currentUser?.relation) {
            url += `&filter[favorites]=${state.currentUser.currentUser.relation}`;
        } else if (filter.is_favorite) {
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }

        const categoryService = new CategoryService([] as Category[]);
        const currentCategoryFilter = categoryService.getCurrentCategoryFilterId();
        if (currentCategoryFilter) {
            url += `&filter[category]=${currentCategoryFilter}`;
        }

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const productsResponse = response.data;

            if (productsResponse.data.length === 0) {
                return thunkAPI.rejectWithValue('No products found');
            }

            if (productsResponse.data.length === 0 && !filter.fullText) {
                return thunkAPI.rejectWithValue('No results were found for');
            }

            return {
                products: productsResponse.data.map(formatProduct),
                totalItems: productsResponse.meta.count,
            };

        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    });

const initialState = {
    loading: false,
    productRange: [] as ProductModel[],
    totalItems: 0,
    page: 1,
    itemsPerPage: 15,
} as ProductRangeSliceModel;

const productRangeSlice = createSlice({
    name: "productRangeSlice",
    initialState,
    reducers: {
        updateRange: (state, action: PayloadAction<ProductModel[]>) => {
            state.productRange = action.payload;
            if (action.payload.length < state.itemsPerPage) {
                state.totalItems = action.payload.length;
            }
        },
        setItemsPerPage: (state, action) => {
            state.itemsPerPage = action.payload as number;
        },
        setPage: (state, action) => {
            state.page = action.payload as number;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProducts.rejected, (state) => {
                state.loading = false;
                state.totalItems = 0;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.productRange = action.payload.products as ProductModel[];
                state.totalItems = action.payload.totalItems;
            })
    }
});

export const {updateRange, setItemsPerPage, setPage} = productRangeSlice.actions;
export default productRangeSlice.reducer;
