import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios from "axios";
import {CheckoutUpdateResponse, CheckoutUpdateSliceModel} from "./checkoutUpdateSliceModel";
import {getCart} from "../../../cart/infrastructure/slices/cartSlice";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

const initialState: CheckoutUpdateSliceModel = {
  loading: false,
  last_response: null,
  error: null,
  message: null
};

export const refreshUpdate = createAsyncThunk(
  "checkoutUpdateSlice/refreshUpdate",
  async (props: { order: number }, thunkAPI) => {
    const {order} = props;
    const lang = i18next.language;
    const formatLang = lang === 'nl' ? '' : `/${lang}`;
    try {
      const response = await axios.get(`${API_BASE_URL}${formatLang}/api/mollie_return/${order}`);

      const payload = response.data as CheckoutUpdateResponse;
      if (!(payload.waiting ?? false)) {
        await thunkAPI.dispatch(getCart());
      }

      return payload;

    } catch (error: unknown) {
      return thunkAPI.rejectWithValue('Something went wrong, please try again');
    }
  }
);

const checkoutUpdateSlice = createSlice({
  name: 'checkoutUpdateSlice',
  initialState,
  reducers: {
    // Reducers can be added here if needed in the future
      clearCheckoutUpdateMessage: state => {
          state.message = null;
      }
  },
  extraReducers: (builder) => {
    builder.addCase(refreshUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshUpdate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(refreshUpdate.fulfilled, (state, action) => {
      state.loading = false;
      const payload = action.payload as CheckoutUpdateResponse;
      state.last_response = payload;

      if (!(payload.waiting ?? false) && payload.message) {
          state.message = {
            type: payload.status === 0 ? 'error' : 'success',
            message: payload.message,
          }
      }
    });
  }
});

export const {clearCheckoutUpdateMessage} = checkoutUpdateSlice.actions

export default checkoutUpdateSlice.reducer;
