import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import {MenuLink, FooterSliceModel, Menu} from "./footerSliceModel";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

interface MenuLinkResponseItem{
    id: string;
    attributes: {
        title: string;
        weight: number;
        drupal_internal__id: number;
        menu_name: string;
        parent: null;
        url: string;
        }
    }

    
export const footerMenu = createAsyncThunk(
    "footerSlice/getFooterPageItems",
    async (menu: string, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        
        const url = `${API_BASE_URL}${formatLang}/jsonapi/menu_items/${menu}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const menuLinkResponse = response.data;

            if (menuLinkResponse.data.length === 0) {
                return thunkAPI.rejectWithValue('no menu links found');
            }

            const menuLinks = response.data.data.map((item: MenuLinkResponseItem) => ({
                id: item.id,
                title: item.attributes.title,
                weight: item.attributes.weight,
                drupal_internal__id: item.attributes.drupal_internal__id,
                menu_name: item.attributes.menu_name,
                url: item.attributes.url,
            }));
            
            return {
                menu: menu,
                links: menuLinks as MenuLink[],
            } as Menu;
            
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    });


const initialState = {
    loading: false,
    loaded: false,
    menus: [] as Menu[],
} as FooterSliceModel;

const footerSlice = createSlice({
    name: "footerSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(footerMenu.pending, (state) => {
                state.loading = true;
            })
            .addCase(footerMenu.rejected, (state) => {
                state.loading = false;
                state.loaded = true;
            })
            .addCase(footerMenu.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;

                const key = state.menus.findIndex(menu => menu.menu == action.payload.menu);
                if (key > -1) {
                    state.menus[key] = action.payload;
                }
                else {
                    state.menus.push(action.payload as Menu);
                }
            })
    }
});

    
export default footerSlice.reducer;