import {ReactNode} from "react";
import {supportedLanguages} from "../../header/modules/features/langSelect";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const cap = (word: string) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
const format = (item: string) => cap(decodeURIComponent(item)).replaceAll('-', ' ');

const Navigation = (props: { hideAssortment: boolean, replacements: object }) => {
    const {t} = useTranslation('common');

    const navigation = useNavigate();

    const items = window.location.pathname.split('/');
    items.shift();

    const first = items[0];
    const firstIsLang = supportedLanguages.map(lang => lang.langcode).includes(first);
    const base = firstIsLang ? ('/' + first) : '';
    if (items && firstIsLang) {
        items.shift();
    }

    const getItemUrl = (delta: number) => {
        let url = base;
        items.forEach((item, pos) => {
            if (pos <= delta) {
                url += '/' + item;
            }
        });

        return url;
    }

    const clickItem = (delta: number, event: { preventDefault: () => void; }) => {
        event.preventDefault();

        navigation(getItemUrl(delta));
    }

    const replacements: {[index: string]:string} = {...props.replacements};

    return <div className={'breadcrumbs'}>
        {(!props.hideAssortment && (!items || items[0] !== 'assortment')) && <a className={'assortment-button'} href={base + '/assortment'} onClick={(event) => {
            event.preventDefault();
            navigation(base + '/assortment');
        }}>
            {t('Assortment')}
        </a>}
        {items.map((item, delta) => <a key={delta} onClick={(event) => clickItem(delta, event)} className={'crumb'} href={getItemUrl(delta)}>
            {item in props.replacements ? replacements[item] : t(format(item))}
        </a>)}
    </div>
}

const defaultReplacements = {};

export const DefaultPage = (props: {
    children: ReactNode,
    className?: string,
    showBreadCrumbs?: boolean,
    hideAssortment?: boolean,
    replacements?: object
}) => {
    let className = props.className ?? '';
    if ((props.showBreadCrumbs ?? true)) {
        className = props.className ? `${props.className} with-breadcrumbs` : 'with-breadcrumbs';
    }

    return (
        <div className={`container p-rel ${className}`}>
            {(props.showBreadCrumbs ?? true) && <Navigation hideAssortment={props.hideAssortment ?? false} replacements={props.replacements ?? defaultReplacements}/>}
            {props.children}
        </div>
    );
}
