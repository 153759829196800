import React from "react";
import {Navigate} from "react-router-dom";
import {CurrentUserSliceDataModel} from "../../../root/login/infrastructure/slices/currentUserSlice/currentUserSliceModel";

type Props = {
    children: JSX.Element
    user: CurrentUserSliceDataModel|undefined
}

export const PublicRoute = ({user, children} : Props): JSX.Element => {
    if (typeof user === 'object' && (user?.id ?? null)) {
        const lang = user.data?.active_relation?.langcode ?? 'nl';
        return <Navigate to={`/${lang}/assortment`} replace />;
    }

    return children;
};