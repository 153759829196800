import {Alert, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppSelector} from "../../../../../hooks/useTypedSelector";
import {Loading} from "../../../../general/features/loading";


export function Totals() {
    const {t} = useTranslation('common');
    const {cart, loading, cartLoading} = useAppSelector((state) => state.cart);
    const {relationLoading, relationData} = useAppSelector(state => state.checkout);

    const isLoading = loading || cartLoading || relationLoading;

    if (isLoading) {
        return <Col className={'d-flex justify-content-center align-items-center h-150 p-rel'} xs={12}>
            <Loading/>
        </Col>
    }

    const matches: string[] = relationData?.free_delivery_amount?.match(/\d+/) ?? [];

    const treshold = matches && matches.length > 0 ? parseInt(matches[0]) : 0;


    const {totals, items, adjustments} = cart;

    const shippingRows = adjustments ? adjustments.filter(item => item.type === 'shipping') : [];
    const taxRows = adjustments ? adjustments.filter(item => item.type === 'tax') : [];

    const toOrder = treshold - (totals?.sub_total?.number ? parseFloat(totals.sub_total.number as string) : 0);

    const formatNum = (number: string) => {
        return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(
            parseFloat(number),
        );
    }

    const toOrderFormatted = formatNum(`${toOrder}`);
    const counter = items ? items.map(item => item.quantity).reduce((partialSum, a) => partialSum + a, 0) : 0;


    return (<>
            <Row className={'mt-3'}>
                <Col className={'text-begin'} xs={8}>{t('Articles')} ({counter})</Col>
                <Col className={'text-end'} xs={4}>{totals?.sub_total?.formatted}</Col>
            </Row>
            {taxRows && taxRows.map((row, delta) => <Row key={delta}>
                <Col className={'text-begin'} xs={8}>{row.label}</Col>
                <Col className={'text-end'} xs={4}>{row?.amount?.formatted}</Col>
            </Row>)}
            {shippingRows && shippingRows.map((row, delta) => <Row key={delta}>
                <Col className={'text-begin'} xs={8}>{t('Transport costs')}</Col>
                <Col className={'text-end'} xs={4}>{row?.amount?.formatted}</Col>
                {treshold > 0 && <Col xs={12}>
                    <small style={{fontSize: '10px'}}>{t('Your Franco delivery amount: {{amount}}', {'amount': formatNum(`${treshold}`)})}</small>
                </Col>}
            </Row>)}

            {(treshold > 0 && toOrder > 0) && (
                <Alert variant={'none'} className={'bg-warning mt-3 mb-3'}>
                        <span
                            dangerouslySetInnerHTML={{__html: t('Spend another to get <strong>FREE</strong> delivery', {toOrderFormatted})}}/>
                </Alert>
            )}

            <Row className={'mb-4'}>
                <Col className={'text-begin'} xs={8}><strong>{t('Total incl. VAT')}</strong></Col>
                <Col className={'text-end'} xs={4}><strong>{totals?.total_price?.formatted}</strong></Col>
            </Row>
        </>
    );
}

