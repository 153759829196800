import {useTranslation} from "react-i18next";
import {PasswordForgottenForm} from "../features/PasswordForgottenForm";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import logo from "../commons/images/logo.png";
import {Link} from "react-router-dom";
import {PageTitle} from "../../../page/features/PageTitle";
import i18next from "i18next";

export function PasswordForgottenPage() {
    const {t} = useTranslation('common');

    return (
        <div className={'App'}>
            <PageTitle title={t('Forgotten password')} hide={true}/>
            <div className={'login-page-wrapper'}>
                <Container>
                    <Row className={'justify-content-center w-100'}>
                        <Col xs={10} md={6} lg={5} className={'login-form'}>
                            <div className={'login-header'}>
                                <Link to={`/${i18next.language}/`}><img loading="lazy" decoding="async" src={logo} alt={'Home'}/></Link>
                            </div>
                            <PasswordForgottenForm />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
