import React from 'react'
import {onProdImgError} from "../../product/modules/pages/product/productCard";

type PropType = {
    selected: boolean
    imgSrc: string
    index: number
    alt: string,
    onClick: () => void
}

export const Thumb: React.FC<PropType> = (props) => {
    const { selected, imgSrc, index, onClick, alt } = props

    return (
        <div
            className={'embla-thumbs__slide'.concat(
                selected ? ' embla-thumbs__slide--selected' : ''
            )}
        >
            <button
                onClick={onClick}
                className={"embla-thumbs__slide__button"}
                type={"button"}
            >
                <div className={"embla-thumbs__slide__number"}>
                    <span>{index + 1}</span>
                </div>
                <img loading="lazy" decoding="async"
                    className={"embla-thumbs__slide__img image-fluid rounded"}
                    src={imgSrc}
                    alt={alt}
                    onError={onProdImgError}
                />
            </button>
        </div>
    )
}
