import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {ProductFavorite, ProductFavoriteSliceModel} from "./productFavoriteSliceModel";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import {RootState} from "../../../../store";
import i18next from "i18next";

const initialState: ProductFavoriteSliceModel = {
    loading: false,
    totalItems: 0,
    favorites: [] as ProductFavorite[],
    loaded: false,
};

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

// Wait until indexed in Solr.
const wait = 200;

export const addProductFavorite = createAsyncThunk(
    "productFavoriteSlice/addProductFavorite",
    async (product: string, thunkAPI) => {
        try {
            const payload = {
                data: {
                    type: "favorite_product--favorite_product",
                    relationships: {
                        product: {
                            data: {
                                type: "commerce_product--default",
                                id: product
                            }
                        }
                    },
                },
            };
            const lang = i18next.language;
            const formatLang = lang === 'nl' ? '' : `/${lang}`;

            await axios.post(API_BASE_URL + formatLang + '/jsonapi/favorite_product/favorite_product', payload, {
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                }
            });

            await new Promise(r => setTimeout(r, wait));

            await thunkAPI.dispatch(getTotalFavoriteItems());

            return product
        } catch (error: unknown) {
            console.error(error);
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);


export const deleteProductFavorite = createAsyncThunk(
    "productFavoriteSlice/deleteProductFavorite",
    async (product: string, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;

            const favorite = state.productFavorite.favorites.find(item => item.product === product) as ProductFavorite;

            if (!favorite || !favorite.favorite) {
                return thunkAPI.rejectWithValue('No favorite found');
            }
            const lang = i18next.language;
            const formatLang = lang === 'nl' ? '' : `/${lang}`;

            await axios.delete(`${API_BASE_URL}${formatLang}/jsonapi/favorite_product/favorite_product/${favorite.favorite}`, {
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                }
            });

            await new Promise(r => setTimeout(r, wait));

            await thunkAPI.dispatch(getTotalFavoriteItems());

            return product;
        } catch (error: unknown) {
            console.error(error);
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

interface FavoriteResponseItem {
    id: string;
    attributes: {
        drupal_internal__product_id: number,
    },
    relationships: {
        is_favorite?: {
            data?: {
                id?: string,
                meta?: {
                    drupal_internal__target_id?: number
                }
            }
        }
    }
}

interface getTotalFavoriteItemsPayloadId {
    product: string;
    favorite?: string|null;
}

interface getTotalFavoriteItemsPayload {
    ids: getTotalFavoriteItemsPayloadId[];
    totalItems: number
}

export const getTotalFavoriteItems = createAsyncThunk(
    "productFavoriteSlice/getTotalItems",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const relation = state?.currentUser?.currentUser?.relation;

            const lang = i18next.language;
            const formatLang = lang === 'nl' ? '' : `/${lang}`;

            const url = `${API_BASE_URL}${formatLang}/jsonapi/index/products/?page[offset]=0&page[limit]=1000&filter[favorites]=${relation}&filter[entity_type]=commerce_product`;

            if (!relation) {
                return thunkAPI.rejectWithValue('No relation found');
            }

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const productsResponse = response.data;

            const items = productsResponse.data.map((item: FavoriteResponseItem) => ({
                product: item.id,
                productId: (item?.attributes?.drupal_internal__product_id) ? item.attributes.drupal_internal__product_id : null,
                favorite: item?.relationships?.is_favorite?.data?.id ?? null,
            }));

            return {
                ids: items,
                totalItems: items.length,
            } as getTotalFavoriteItemsPayload;

        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

const productFavoriteSlice = createSlice({
    name: 'productFavoriteSlice',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder.addCase(addProductFavorite.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addProductFavorite.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(addProductFavorite.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(deleteProductFavorite.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteProductFavorite.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(deleteProductFavorite.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getTotalFavoriteItems.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.totalItems) {
                state.totalItems = action.payload.totalItems;
            }
            if (action?.payload?.ids) {
                state.favorites = action.payload.ids as ProductFavorite[];
            }
            state.loaded = true;
        });
        builder.addCase(getTotalFavoriteItems.rejected, (state) => {
            state.loading = false;
        });
    }
});

export default productFavoriteSlice.reducer;
