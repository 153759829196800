import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios, {AxiosError} from "axios";
import {AccountSliceModel, ChangePasswordResponseModel} from "../../domain/models/accountSliceModel";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

const initialState: AccountSliceModel = {
    loading: false,
    loaded: false,
    validation: [],
    success: null,
    error: null
};

export const changePassword = createAsyncThunk(
    "accountSlice/changePassword",
    async (props: { new: string, current: string, repeat: string }, thunkAPI) => {

        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = API_BASE_URL + `${formatLang}/api/account/change-password`;

        try {
            const response = await axios.post(url, props, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response.data as ChangePasswordResponseModel;
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

const accountSlice = createSlice({
    name: 'accountSlice',
    initialState,
    reducers: {
        // Reducers can be added here if needed in the future
    },
    extraReducers: (builder) => {
        builder.addCase(changePassword.pending, (state) => {
            state.loading = true;
            state.validation = [];
            state.success = null;
            state.loaded = false;
        });
        builder.addCase(changePassword.rejected, (state, action) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload as string;
        });
        builder.addCase(changePassword.fulfilled, (state, action) => {
            state.loaded = true;
            state.loading = false;
            state.success = action.payload.status === 1;
            state.validation = Object.values(action.payload.validation ?? {});
        });
    }
});

export default accountSlice.reducer;
