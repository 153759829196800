import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {PageTitle} from "../../../page/features/PageTitle";
import {DefaultPage} from "../../../page/features/default";
import {ProductCard} from "./product/productCard";
import {Alert, Col, Row} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {
    getProducts,
    setItemsPerPage,
    setPage,
    updateRange
} from "../../infrastructure/slices/productRangeSlice/productRangeSlice";
import {MaxPageResults} from "./productRange/maxPageResults";
import {useLocation, useNavigate} from "react-router-dom";
import ProductMenu from "./productRange/ProductMenu";
import Pagination from "./productRange/Pagination";
import {ProductModel} from "../../domain/models/productModel";
import {Loading} from "../../../general/features/loading";
import {PriceRequestModal} from "./product/priceRequestModal";

export function AssortmentPage() {
    const [showSearchMessage, setShowSearchMessage] = useState(true);
    const {t} = useTranslation('common');
    const {productRange, loading, totalItems, itemsPerPage, page} = useAppSelector((state) => state.productRange);
    const {favorites} = useAppSelector((state) => state.productFavorite);
    const dispatch = useAppDispatch();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let searchQuery = query.get('search') ?? '';
    const pageFromUrl = query.get('page') ?? '1';
    const itemsFromUrl = query.get('items');
    const categoryFromUrl = query.get('category');
    const showFavoritesFromUrl = location.pathname === '/nl/assortment/order-list' || location.pathname === '/en/assortment/order-list';

    useEffect(() => {
        if (location.pathname.startsWith('/nl/assortment') || location.pathname.startsWith('/en/assortment')) {
            window.scrollTo({top: 120, behavior: 'smooth'});
        }
    }, [location]);

    const resetPageItems = async () => {
        setShowSearchMessage(true);
        await dispatch(updateRange([] as ProductModel[]));
        await dispatch(getProducts({fullText: searchQuery, is_favorite: showFavoritesFromUrl}));
    }

    useEffect(() => {
        resetPageItems();
    }, [searchQuery, itemsPerPage, page, showFavoritesFromUrl, categoryFromUrl]);

    useEffect(() => {
        if (showFavoritesFromUrl) {
            dispatch(getProducts({fullText: searchQuery, is_favorite: showFavoritesFromUrl}));
        }
    }, [favorites]);

    useEffect(() => {
        if (pageFromUrl !== null && !isNaN(parseInt(pageFromUrl))) {
            dispatch(setPage(parseInt(pageFromUrl)));
        }
    }, [pageFromUrl]);

    useEffect(() => {
        if (itemsFromUrl !== null && !isNaN(parseInt(itemsFromUrl))) {
            dispatch(setItemsPerPage(parseInt(itemsFromUrl)));
        }
    }, []);

    function clearSearch() {
        searchQuery = '';
        setShowSearchMessage(false);
        dispatch(getProducts({fullText: searchQuery, is_favorite: showFavoritesFromUrl}));
    }

    const navigation = useNavigate();

    const changePage = (newPage: number) => {
        const url = new URL(window.location.href);
        url.searchParams.set('page', newPage.toString());

        navigation(`${url.pathname}${url.search}`);
        dispatch(setPage(newPage));
    }

    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [minQuantity, setMinQuantity] = useState(0);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');

    const openPriceRequestModal = (productId: string, productName:string, quantity: number, minQuantity?: number) => {
      setProductId(productId);
      setProductName(productName);
      setQuantity(quantity);
      setOpen(true);
      setMinQuantity(minQuantity ?? 1);
    }

    const closePriceRequestModal = () => {
      setProductId('');
      setProductName('');
      setQuantity(0);
      setOpen(false);
      setMinQuantity(0);
    }

    return (
        <DefaultPage className={'mt-5 product-range'}>
            <Row>
                <Col xs={{order: 1, span: 12}}
                     sm={{order: 1, span: 12}}
                     md={{order: 0, span: 3}}
                     className={'mb-3'}
                >
                    <ProductMenu/>
                </Col>
                <Col xs={{order: 1, span: 12}}
                     sm={{order: 1, span: 12}}
                     md={{order: 0, span: 9}}>

                    {!showFavoritesFromUrl ?
                        <PageTitle title={t('Assortment')}/>
                        : <PageTitle title={t('My order list')}/>}

                    <Row className={'align-items-center mt-4 mb-4'}>
                        <Col md={4}>{totalItems} {t('results')}</Col>
                        <Col md={8}>
                            <MaxPageResults/>
                        </Col>
                    </Row>

                    {showSearchMessage && searchQuery && (
                        <Row>
                            <Col>
                                <Alert variant={'info'} onClose={() => clearSearch()}
                                       dismissible>{t('Search results for', {searchText: searchQuery})}
                                </Alert>
                            </Col>
                        </Row>
                    )}

                    <Row className={'p-rel mh-500'}>
                        {loading ? <Loading/> : (
                            <>
                                {(productRange.length > 0)
                                    ? <>
                                        {productRange.map((product, index) => (
                                            <Col key={index} xs={12} sm={6} xl={4} className={'mb-4'}>
                                                <ProductCard product={product} key={index} onPriceRequest={openPriceRequestModal}/>
                                            </Col>
                                        ))}
                                        <PriceRequestModal productName={productName} open={open} onClose={closePriceRequestModal} count={quantity} productId={productId} minCount={minQuantity} />
                                  </>
                                    : <><Alert variant={'warning'}>{t('No products found')}</Alert></>}
                            </>
                        )}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Pagination page={page} maxPage={Math.ceil(totalItems / itemsPerPage)}
                                        setPage={changePage}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </DefaultPage>
    );
}
