import {
    BiChevronLeft,
    BiChevronRight,
    BiChevronsLeft,
    BiChevronsRight
} from "react-icons/bi";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

type DescribableFunction = {
    (newPage: number, event: {preventDefault(): void}): void;
};

const maxVisibleLinks = 5;

const setPageToUrl = (page: number|null) => {
    const url = new URL(window.location.href);

    if (url.searchParams.has('page')) {
        url.searchParams.delete('page');
    }
    url.searchParams.set('page', `${page}`);

    return `${url.pathname}${url.search}`;
}

const PaginationLink = (props: { setPage: DescribableFunction, currentPage: number, page: number }) => {
    const {page, currentPage, setPage} = props;

    const active = page === currentPage;

    const className = `pagination-link ${active ? 'active' : ''}`;

    return <Button onClick={(event) => setPage(page, event)} variant={'link'} disabled={active}
                   className={className} href={setPageToUrl(page)}>
        {page}
    </Button>
}

const Pagination = (props: { page: number, maxPage: number, setPage: (page: number) => void }) => {
    const {page, setPage, maxPage} = props;

    const {t} = useTranslation('common');

    const links = [];
    const start = Math.max(1, Math.min(maxPage - maxVisibleLinks + 1, page - Math.floor(maxVisibleLinks / 2)));
    const end = Math.min(start + maxVisibleLinks - 1, maxPage);

    for (let x = start; x <= end; x++) {
        links.push(x);
    }

    const handlePage = (page: number, event: {preventDefault: () => void}) => {
        event.preventDefault();

        setPage(page);
    }

    return <div className={'pagination'}>
        {maxPage > 1 && <>
            <div>
                <Button className={'px-1 px-sm-2'} onClick={(event) => handlePage(1, event)} variant={'link'} disabled={page === 1} href={setPageToUrl(1)}>
                    <div className={'d-md-none'}><BiChevronsLeft size={'25px'} color={'white'}/></div>
                    <div className={'d-none d-md-block'}>{t('First page')}</div>
                </Button>
                <Button className={'px-1 px-sm-2'} onClick={(event) => handlePage(page - 1, event)} variant={'link'} disabled={page === 1} href={setPageToUrl(page - 1)}>
                    <BiChevronLeft size={'25px'} color={'white'}/>
                </Button>
            </div>
            <div className={'pagination-links'}>
                {links.map(link => <PaginationLink key={link} page={link} currentPage={page} setPage={handlePage}/>)}
            </div>
            <div>
                <Button className={'px-1 px-sm-2'} onClick={(event) => handlePage(page + 1, event)} variant={'link'} disabled={page === maxPage} href={setPageToUrl(page + 1)}>
                    <BiChevronRight size={'25px'} color={'white'}/>
                </Button>
                <Button className={'px-1 px-sm-2'} onClick={(event) => handlePage(maxPage, event)} variant={'link'} disabled={page === maxPage} href={setPageToUrl(maxPage)}>
                    <div className={'d-md-none'}><BiChevronsRight size={'25px'} color={'white'}/></div>
                    <div className={'d-none d-md-block'}>{t('Last page')}</div>
                </Button>
            </div>
        </>}
    </div>
}

export default Pagination;