import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {BiChevronDown} from "react-icons/bi";

export const supportedLanguages = [
    {
        langcode: 'en',
        icon: 'gb',
        name: 'EN',
        description: 'English',
    },
    {
        langcode: 'nl',
        icon: 'nl',
        name: 'NL',
        description: 'Nederlands',
    }
];

const LangSelect = () => {
    const {lang} = useParams();
    const navigation = useNavigate();
    const {i18n} = useTranslation('common');

    const currentLang = supportedLanguages.find(x => x.langcode === lang);

    const switchLang = (langcode: string) => {
        i18n.changeLanguage(langcode);
        navigation(`/${langcode}${location.pathname.startsWith(`/${lang}`) ? location.pathname.substring(`/${lang}`.length) : location.pathname}`);
    }

    const title = <>
        <span className={`me-2 fi fi-${currentLang?.icon}`}></span>
         {currentLang?.name}
        <span className={'dropdown-icon'}><BiChevronDown/></span>
    </>;

    return (
        <NavDropdown title={title} id="lang-dropdown-corny-bakers" className={"custom-dropdown lang-select"}>
            {supportedLanguages.map((option, delta) => (
                <NavDropdown.Item key={delta} onClick={() => switchLang(option.langcode)}>
                    <span className={`me-1 fi fi-${option.icon}`}></span> {option.name}
                    {lang === option.langcode && <i className="fa fa-check text-success ms-2" />}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
};

export default LangSelect;
