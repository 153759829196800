import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import { ContentSliceModel, MenuLinkData, PageType } from "./contentSliceModel";
import NotFoundPage from "../../modules/pages/404Page";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export const getNodeItems = createAsyncThunk(
    "contentSlice/getNodeItems",
    async (path: string, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        
        const url = `${API_BASE_URL}${formatLang}/router/translate-path?path=${path}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const menuLinkResponse = response.data.jsonapi.individual;

            if (menuLinkResponse === '/404') {
                return {
                    title: "",
                    processed: "",
                    type: PageType.NotFound
                };
            }

            const response2 = await axios.get(menuLinkResponse, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return {
                title: response2.data.data.attributes.title,
                processed: response2.data.data.attributes.body,
                type: PageType.Page
            };

        } catch (error: unknown) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                return <NotFoundPage/>;
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    });

const initialState = {
    loading: false,
    loaded: false,
    content: {title:"", processed:"", type: PageType.Page} as MenuLinkData,
} as ContentSliceModel;

const contentSlice = createSlice({
    name: "contentSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNodeItems.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNodeItems.rejected, (state) => {
                state.loading = false;
                state.loaded = true;
            })
            .addCase(getNodeItems.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.content = (action.payload as MenuLinkData)
            })
    }
});
    
export default contentSlice.reducer;
