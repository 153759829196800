import React, {useState, useEffect, useCallback} from 'react'
import useEmblaCarousel, {EmblaOptionsType} from 'embla-carousel-react'
import {Thumb} from "./emblaCarouselThumbsButton";
import {ImageModel} from "../../product/domain/models/imageModel";
import default640 from "../../product/modules/commons/images/product/default-640.png";

type PropType = {
    options?: EmblaOptionsType,
    images: ImageModel[]
}

const onProdImgErrorBig = (e: React.SyntheticEvent) => {
    (e.target as HTMLImageElement).src = default640;
}

const EmblaCarousel: React.FC<PropType> = (props) => {
    const {images, options} = props
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options)
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
        containScroll: 'keepSnaps',
        dragFree: true
    })

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) return
            emblaMainApi.scrollTo(index)
        },
        [emblaMainApi, emblaThumbsApi]
    )

    const onSelect = useCallback(() => {
        if (!emblaMainApi || !emblaThumbsApi) return
        setSelectedIndex(emblaMainApi.selectedScrollSnap())
        emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())
    }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

    useEffect(() => {
        if (!emblaMainApi) return
        onSelect()
        emblaMainApi.on('select', onSelect)
        emblaMainApi.on('reInit', onSelect)
    }, [emblaMainApi, onSelect])

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaMainRef}>
                <div className="embla__container">
                    {images.map((image, index) => (
                        <div className="embla__slide" key={index}>
                            <div className="embla__slide__number">
                                <span>{index + 1}</span>
                            </div>
                            <img loading="lazy" decoding="async"
                                className={"embla__slide__img img-fluid rounded"}
                                src={image.large}
                                alt={image.name}
                                onError={onProdImgErrorBig}
                            />
                        </div>
                    ))}
                </div>
            </div>


            <div className="embla-thumbs">
                <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
                    <div className="embla-thumbs__container">
                        {images.map((image, index) => (
                            <Thumb
                                onClick={() => onThumbClick(index)}
                                selected={index === selectedIndex}
                                index={index}
                                imgSrc={image.small_thumbnail}
                                key={index}
                                alt={image.name}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmblaCarousel
