import {useAppDispatch, useAppSelector} from "../../../hooks/useTypedSelector";
import {toast, ToastContainer} from "react-toastify";
import React, {useEffect} from "react";
import {OnChangeCallback} from "react-toastify/dist/core/eventManager";
import {clearCheckoutUpdateMessage} from "../../checkout/infrastructure/slices/checkoutUpdateSlice";

export const containerId: string|number = 'global_messages';

const Messages = () => {

    const {message} = useAppSelector(state => state.checkoutUpdate);
    const dispatch = useAppDispatch();

    const onChange:OnChangeCallback = payload => {
        const data: {
            origin?: string
        } = payload?.data;

        if (payload.status === "removed" && (data.origin ?? '') === 'checkoutUpdate') {
            dispatch(clearCheckoutUpdateMessage());
        }
    };

    toast.onChange(onChange);

    useEffect(() => {
        if (message && typeof message === "object") {
            const handler = message.type === 'error' ? toast.error : toast.success;

            handler(message.message, {
                containerId: containerId,
                data: {
                    origin: 'checkoutUpdate'
                }
            });

        }
    }, [message]);


    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
            containerId={containerId}
            style={{
                marginTop: '106px',
            }}
        />
    );
}

export default Messages;