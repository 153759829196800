import {useTranslation} from "react-i18next";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useTypedSelector";
import {deleteFromCart} from "../../../infrastructure/slices/cartSlice";
import default150 from '../../../../product/modules/commons/images/product/default-150.png';
import {Button} from "react-bootstrap";
import {FavoriteBtn} from "../../../../productFavorites/modules/commons/favoriteBtn";
import {AddToCart} from "./addToCart";
import {BiTrash} from "react-icons/bi";
import {OrderItemFormatted} from "../../../domain/models/cartApiResponseModel";
import {Loading} from "../../../../general/features/loading";
import {formatNum} from "../../../../general/features/util";

export function CartRow(props: { item: OrderItemFormatted }) {
    const {item} = props;
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();

    const { cartLoading, loading, cartLoaded} = useAppSelector((state) => state.cart);

    const handleDeleteFromCart = (product: string) => {
        dispatch(deleteFromCart({product}));
    };

    const isLoading = cartLoading || loading;

    return <>
        <tr className={'cart-table-row'}>
            {!cartLoaded ? (<td colSpan={9999} className={'p-rel'}><Loading /></td>) : (<>
                <td>
                    <img loading="lazy" decoding="async"
                        src={item.product_images.length > 0 ? item.product_images[0] : default150}
                        alt={item.name}
                        width={'100'}/>
                </td>
                <td className={'d-flex flex-column justify-content-between'}>
                    <div>
                        <h6>{item.name}</h6>
                        <span>{t('Article number')} {item.sku}</span>
                    </div>
                    <div className={'d-flex gap-2'}>
                        <FavoriteBtn size={'20px'} variant={'cart'} productId={item.product_uuid}/>
                        <Button className={'btn-cart'} variant={'dark'} onClick={() => handleDeleteFromCart(item?.order?.item ?? '')} disabled={isLoading}>
                            <BiTrash size={'20px'}/>
                        </Button>
                    </div>
                </td>
                <td className={'qty'}>
                    <AddToCart variationId={item.variation_id} showBtn={false} variant={'cart'} disabled={isLoading}/>
                </td>
                <td className={'text-end cart-price'}>
                    {formatNum(`${item.unit_price}`)}
                </td>
                <td className={'text-end'}>
                    {item.price}
                </td>
            </>)}
        </tr>
    </>
}

