import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios, {RawAxiosRequestHeaders} from "axios";
import {logout} from "../../../content/modules/pages/AccountMenu";
import {AppDispatch} from "../../../../store";

interface CurrentUerRequestHeaders {
    'Content-Type': string,
    'Authorization'?: string,
}

export default class CurrentUserService {
    private readonly api_token: string|null;
    private readonly api_url: string;

    constructor() {
        this.api_token = null;
        this.api_url = CornyBakersEnv.API_BASE_URL;
    }

    init = (dispatch: AppDispatch) => {
        const headers: CurrentUerRequestHeaders = {
            'Content-Type': 'application/json'
        }

        if (this.api_token) {
            // TODO: headers.Authorization = `Bearer ${this.api_token}`;
        }

        axios.defaults.withCredentials = true;

      const axiosInstance = axios.create({
        baseURL: this.api_url,
        headers: headers as RawAxiosRequestHeaders,
      });

      axiosInstance.interceptors.response.use(response => response,
        async error => {
          let loc = window.location.pathname;

          // Remove langcode.
          if (loc.startsWith('/en/') || loc.startsWith('/nl/')) {
            loc = loc.substring(3);
          }

          // Redirect if logged out and not on a /user/* page.
          if (error.response && error.response.status === 403 && !loc.startsWith('/user/')) {
            await logout(dispatch);
          }
          return Promise.reject(error);
        }
      );

      return axiosInstance;
    };

    getCurrentUser = (dispatch: AppDispatch) => {
        return this.init(dispatch).get('/jsonapi/current-user?jsonapi_include=1&include=active_relation')
    }
}
