import {AsyncThunk, AsyncThunkPayloadCreator, configureStore} from '@reduxjs/toolkit'
import UserSlice from "./root/login/infrastructure/slices/userSlice/userSlice";
import ResetPasswordSlice from "./root/login/infrastructure/slices/resetPasswordSlice/resetPasswordSlice";
import ResetPasswordDataSlice from "./root/login/infrastructure/slices/resetPasswordDataSlice/resetPasswordDataSlice";
import productSlice from "./root/product/infrastructure/slices/productSlice/productSlice";
import cartSlice from "./root/cart/infrastructure/slices/cartSlice";
import currentUserSlice from "./root/login/infrastructure/slices/currentUserSlice/currentUserSlice";
import productRangeSlice from "./root/product/infrastructure/slices/productRangeSlice/productRangeSlice";
import productFavoriteSlice from "./root/productFavorites/infrastructure/slices/productFavoriteSlice";
import MenuSlice from "./root/header/infrastructure/slices/menuSlice/menuSlice";
import FooterSlice from "./root/footer/infrastructure/slices/footerSlice/footerSlice"
import contentSlice from './root/content/infrastructure/contentSlice/contentSlice';
import CheckoutSlice from "./root/checkout/infrastructure/slices/checkoutSlice";
import CheckoutCompletedSlice from "./root/checkout/infrastructure/slices/checkoutCompletedSlice";
import CheckoutUpdateSlice from "./root/checkout/infrastructure/slices/checkoutUpdateSlice";
import OrdersSlice from "./root/account/infrastructure/slices/ordersSlice";
import AccountSlice from "./root/account/infrastructure/slices/accountSlice";

const store = configureStore({
    reducer: {
        auth: UserSlice,
        resetPassword: ResetPasswordSlice,
        resetPasswordData: ResetPasswordDataSlice,
        product: productSlice,
        cart: cartSlice,
        currentUser: currentUserSlice,
        productRange: productRangeSlice,
        productFavorite: productFavoriteSlice,
        menu: MenuSlice,
        link: FooterSlice,
        content: contentSlice,
        checkout: CheckoutSlice,
        checkoutUpdate: CheckoutUpdateSlice,
        checkoutCompleted: CheckoutCompletedSlice,
        orders: OrdersSlice,
        account: AccountSlice
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;

declare module "@reduxjs/toolkit" {
    type AsyncThunkConfig = {
        state?: unknown;
        dispatch?: AppDispatch;
        extra?: unknown;
        rejectValue?: unknown;
        serializedErrorType?: unknown;
    };

    function createAsyncThunk<
        Returned,
        ThunkArg = void,
        ThunkApiConfig extends AsyncThunkConfig = {
            state: RootState
        }
    >(
        typePrefix: string,
        payloadCreator: AsyncThunkPayloadCreator<
            Returned,
            ThunkArg,
            ThunkApiConfig
        >,
        options?: unknown
    ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>;
}
