import { Col, Container, Row } from "react-bootstrap";
import { FooterNavigation } from "./footerNavigation";
import {useTranslation} from "react-i18next";

import linkedInLogo from "../commons/images/linkedin.png";
import facebookLogo from "../commons/images/facebook.png";
import youtubeLogo from "../commons/images/youtube.png";
import instagramLogo from "../commons/images/instagram.png";
import cornyLogo from "../commons/images/logo.png";

export function Footer() {
  const {t} = useTranslation('common');

  return (
    <div className="footer" style={{ backgroundColor: "black", marginTop: "30px" }}>
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={3} className="pt-4">
            <FooterNavigation label={t("Order")} menu="webshop-footer-order"/>
          </Col>
          <Col xs={12} sm={6} lg={3} className="pt-4">
            <FooterNavigation label={t("My page")} menu="webshop-footer-my-page"/>
          </Col>
          <Col xs={12} sm={6} lg={3} className="pt-4">
            <p style={{ paddingTop: "40px", fontWeight: "bold", paddingBottom: "20px", margin: '0px', paddingLeft: '14px' }}>{t('Do you want to follow us?')}</p>
            <a href="https://www.linkedin.com/company/corny-bakers/"><img loading="lazy" decoding="async" src={linkedInLogo} alt="LinekdIn" style={{ height: "35px", width: "35px", marginRight: "10px", marginLeft: '14px' }} /></a>
            <a href="https://www.facebook.com/cornybakers"><img loading="lazy" decoding="async" src={facebookLogo} alt="Facebook" style={{ height: "30px", width: "30px", marginRight: "10px" }} /></a>
            <a href="https://www.youtube.com/"><img loading="lazy" decoding="async" src={youtubeLogo} alt="Youtube" style={{ height: "40px", width: "40px"}} /></a>
            <a href="https://www.instagram.com/"><img loading="lazy" decoding="async" src={instagramLogo} alt="Instagram" style={{ height: "30px", width: "30px", marginRight: "10px", marginLeft: '14px' }} /></a>
          </Col>
          <Col xs={12}  sm={6} lg={3} className="pt-4">
            <p style={{ paddingTop: "40px", paddingBottom: "20px", fontWeight: "bold", marginBottom: "0px", paddingLeft: '14px' }}>Corny bakers</p>
            <p style={{ paddingBottom: "20px", lineHeight: "2", paddingLeft: '14px' }}>{t("The Mexican wholesaler Corny Bakers has been an established name in Europe for Mexican food products since 1972.")}</p>
            <p style={{ marginBottom: "0px", lineHeight: "2", paddingLeft: '14px' }}>+31 (0)294 417 897</p>
            <p style={{ paddingLeft: '14px'}}>sales@cornybakers.nl</p>
          </Col>
        </Row>
      </Container>
      <div style={{ borderTop: "2px solid #262626 ", marginTop: "40px" }}></div>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={9} md={12} lg={12} className="text-center">
            <img loading="lazy" decoding="async" src={cornyLogo} alt="CornyBakersLogo" style={{ height: "auto", width: "200px", paddingTop: "30px" }} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: '40px' }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ margin: '0px' }}>{t('© 2023 Corny Bakers. All rights reserved')}</p>
              <FooterNavigation label="" menu="webshop-footer-menu" horizontal />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
