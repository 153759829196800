import default150 from "../../../../product/modules/commons/images/product/default-150.png";
import React, {useMemo} from "react";
import {CompletedOrder} from "../../../infrastructure/slices/checkoutCompletedSliceModel";
import {useTranslation} from "react-i18next";


const OrderOverview = (props: { order: CompletedOrder }) => {
    const {order} = props;
    const {t} = useTranslation('common');

    const headers = useMemo(() => [
        {
            text: '',
            className: 'cart-th-image',
        },
        {
            text: t('Description'),
            className: 'cart-th-desc',
        },
        {
            text: t('Qty'),
            className: 'cart-th-qty text-center',
        },
        {
            text: t('Price'),
            className: 'cart-th-price text-end',
        },
        {
            text: t('Total'),
            className: 'cart-th-total text-end',
        },
    ], [t]);

    const formatNum = (number: string) => {
        return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(
            parseFloat(number),
        );
    }

    const shippingCosts = order && (order.adjustments?.length ?? 0) > 0 ? order.adjustments.find(adj => adj.type === 'shipping') : null;
    const vatCosts = order && (order.order_total?.adjustments?.length ?? 0) > 0 ? order.order_total.adjustments.find(adj => adj.type === 'tax') : null;

    const amts = order && (order.order_items?.length ?? 0) > 0 ? order.order_items.map(item => item.total_price.number) : [];

    const vatTotal = (
        order && (order.order_items?.length ?? 0) > 0 ?
            order.order_items
                .map(item => item.adjustments.find(x => x.type === 'tax'))
                .map(x => x ?
                    parseFloat(x.amount.replace(',', '.').split(' ')[0])
                    : 0
                )
            : []
    ).reduce((total, amt) => total + amt, 0);

    const subtotal = amts.reduce((partialSum, a) => partialSum + parseFloat(a as string), 0);


    if ((order?.order_items?.length ?? 0) < 1) {
        return  <></>
    }

    const counter = order.order_items ? order.order_items.map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0) : 0;


    return <div className={'checkout-completed-overview cart'}>
        <div className="table-responsive">
            <table className={'w-100'}>
                <thead>
                <tr className={'cart-table-header'}>
                    {headers.map((header, delta) => <th key={delta} className={header.className}>
                        {header.text}
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {order.order_items.map((item, delta) => <tr key={delta}>
                    <td>
                        {item?.product_images === undefined ? (
                            <img loading="lazy" decoding="async"
                                src={default150}
                                alt={item.purchased_entity.title}
                                width={'100'}/>
                        ) : (
                            <img loading="lazy" decoding="async"
                                src={item.product_images && (item?.product_images?.length ?? 0) > 0 ? item.product_images[0].image_style_uri.thumbnail ?? default150 : default150}
                                alt={item.purchased_entity.title}
                                width={'100'}/>
                        )}
                    </td>
                    <td className={'d-flex flex-column justify-content-between'}>
                        <div>
                            <h6>{item.purchased_entity.title}</h6>
                            <span>{t('Article number')} {item.purchased_entity.sku}</span>
                        </div>
                    </td>
                    <td className={'text-center qty'}>
                        {parseInt(item.quantity)}
                    </td>
                    <td className={'text-end cart-price'}>
                        {item.unit_price.formatted}
                    </td>
                    <td className={'text-end'}>
                        {item.total_price.formatted}
                    </td>
                </tr>)}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={3}></td>
                    <td>{t('Articles')} ({counter})</td>
                    <td className={'text-end'}>{formatNum(`${subtotal}`)}</td>
                </tr>
                {vatCosts && <tr>
                    <td colSpan={3}></td>
                    <td>{vatCosts.label}</td>
                    <td className={'text-end'}>
                        {formatNum(`${vatTotal}`)}
                    </td>
                </tr>}
                {shippingCosts && <tr>
                    <td colSpan={3}></td>
                    <td>{t('Transport costs')}</td>
                    <td className={'text-end'}>
                        {formatNum(shippingCosts.amount.replace(',', '.').split(' ')[0])}
                    </td>
                </tr>}
                <tr className={'total'}>
                    <td colSpan={3}></td>
                    <td>{t('Total incl. VAT')}</td>
                    <td className={'text-end'}>{order.total_price.formatted}</td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
}

export default OrderOverview;