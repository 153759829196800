import {useTranslation} from "react-i18next";
import {BaseAccountPage} from "../Features/baseAccountPage";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {
    getOrders,
    itemsPerPage,
    setPage
} from "../../infrastructure/slices/ordersSlice";
import {Loading} from "../../../general/features/loading";
import {Col, Row} from "react-bootstrap";
import {BiChevronRight} from "react-icons/bi";
import {useNavigate, useParams} from "react-router-dom";
import Pagination from "../../../product/modules/pages/productRange/Pagination";

export const formatOrderDate = (placed: string): string => {
    const date = placed ? new Date(placed.split('T')[0]) : new Date();

    const month = date.toLocaleString('nl-NL', { month: 'long' });

    return `${date.getDate()} ${month} ${date.getFullYear()}`;
}

export function OrdersPage() {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();
    const {loading, orders,page, totalItems} = useAppSelector(state => state.orders)
    const {lang} = useParams();

    useEffect(() => {
        if (!loading) {
            dispatch(getOrders());
        }
    }, [page]);

    const navigation = useNavigate();

    const changePage = (newPage: number) => {
        const url = new URL(window.location.href);
        url.searchParams.set('page', newPage.toString());

        navigation(`${url.pathname}${url.search}`);
        dispatch(setPage(newPage));
    }

    const handleClick = (event: {preventDefault(): void }, id: string) => {
        event.preventDefault();

        navigation(`/${lang}/account/orders/${id}`);
    }

    return (
        <BaseAccountPage pageTitle={t('Orders')}>
            <Row>
                <Col xs={12} className={'mb-4'}>
                    <h3>{t('My orders')}</h3>
                </Col>
                <div className={'p-rel mh-200'}>
                    {loading ? <Loading /> : (
                        orders.map((order, delta) => <Col xs={12} key={delta} className={'order-link'}>
                            <div>{order.attributes.placed ? formatOrderDate(order.attributes.placed) : ''} | {t('Order number')} {order.attributes.order_number}</div>
                            <div>
                                <b>{t('Total (incl. vat)')} {order.attributes.total_price.formatted}</b>
                                <a className={'rounded-circle order-button'} href={`/${lang}/account/orders/${order.id}`}
                                   onClick={event => handleClick(event, order.id)}>
                                    <BiChevronRight size={'20px'} color={'white'} />
                                </a>
                            </div>
                        </Col>)
                    )}
                </div>
                <Col xs={12}>
                    <Pagination page={page} maxPage={Math.ceil(totalItems / itemsPerPage)}
                                setPage={changePage}/>
                </Col>
            </Row>

        </BaseAccountPage>
    );
}

