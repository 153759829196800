import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {BaseAccountPage} from "../Features/baseAccountPage";
import {OrderHeader} from "../../../checkout/modules/pages/checkoutCompletedPage";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import React, {useEffect} from "react";
import {getOrderInfo} from "../../../checkout/infrastructure/slices/checkoutCompletedSlice";
import {Loading} from "../../../general/features/loading";
import OrderOverview from "../../../checkout/modules/pages/components/orderOverview";
import {CompletedOrder} from "../../../checkout/infrastructure/slices/checkoutCompletedSliceModel";
import {Button} from "react-bootstrap";
import {FaAngleRight} from "react-icons/fa";
import {addToCartMultiple} from "../../../cart/infrastructure/slices/cartSlice";
import {toast} from "react-toastify";

export function OrderDetailPage() {
    const {t} = useTranslation('common');
    const {id} = useParams();

    const dispatch = useAppDispatch();
    const {order, loading, loaded} = useAppSelector(state => state.checkoutCompleted);

    useEffect(() => {
        if (!loading) {
            dispatch(getOrderInfo({order: id as string}));
        }
    }, []);

    const handleClick = async () => {
        if (!order || order.order_items.length < 1) {
            return;
        }

        const map = order.order_items.map(orderItem => ({
            quantity: parseInt(orderItem.quantity),
            id: orderItem.purchased_entity.drupal_internal__variation_id,
        }));

        await dispatch(addToCartMultiple({map: map}));

        toast.success(t('Added to you cart'));
    }

    const replacements: {[index: string]:string} = {};
    if (!loading) {
        replacements[id as string] = order?.order_number ?? t('Order');
    }

    return (
        <BaseAccountPage pageTitle={`${t('Order')} #${id}`} replacements={replacements}>
            <div className={'checkout-completed'}>
                <div className={'p-rel mh-350'}>
                    {(!loaded && loading) ? <Loading/> : <>
                        <OrderHeader placed={order?.placed ?? ''} identifier={`${order?.order_number ?? ''}`}/>
                        <OrderOverview order={order as CompletedOrder} key={order?.order_items?.length ?? 0}/>
                    </>}
                </div>
                {(!loading && (order?.order_items?.length ?? 0) > 0) && <div className={'d-flex flex-row-reverse py-3'}>
                    <Button variant={'danger'} onClick={handleClick} >{t('Order again')}<FaAngleRight/></Button>
                </div>}
            </div>
        </BaseAccountPage>
    );
}

