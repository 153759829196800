import {PageTitle} from "../../../page/features/PageTitle";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {DefaultPage} from "../../../page/features/default";
import {Loading} from "../../../general/features/loading";
import {useTranslation} from "react-i18next";
import {CartTable} from "./cart/cartTable";
import {Totals} from "./cart/totals";
import {useNavigate, useParams} from "react-router-dom";
import {FaAngleLeft} from "react-icons/fa";
import {useAppSelector} from "../../../../hooks/useTypedSelector";

export function CartPage() {
    const {cart, loading, cartLoaded} = useAppSelector((state) => state.cart);
    const {lang} = useParams();
    const {t} = useTranslation('common');
    const navigation = useNavigate();

    const gotoAssortiment = () => {
        navigation(`/${lang}/assortment`);
    }

    return (
        <DefaultPage className={"mt-5"}>
            <PageTitle title={t('Cart')}/>
            <Row>
                <Col xs={{order: 1, span: 12}}
                     lg={{order: 0, span: 9}}
                     className={'mb-3'}>
                        {(cartLoaded && (cart?.items?.length ?? 0) === 0) && <Alert variant={'info'}>{t('Cart is empty')}</Alert>}
                    <div className={'p-rel mh-200'}>
                        {loading && <Loading />}
                        {(cartLoaded && (cart?.items?.length ?? 0) > 0) && <CartTable items={cart.items}/>}
                    </div>
                </Col>
                <Col xs={{order: 1, span: 12}}
                     lg={{order: 0, span: 3}}
                     className={'p-rel mh-150 mb-3'}
                >
                    {(loading && !cartLoaded) && <Loading />}
                    {(cart?.items?.length ?? 0) > 0 && <Totals/>}
                </Col>
            </Row>
            <Button variant={'danger'} onClick={gotoAssortiment}><FaAngleLeft/> {t('Continue shopping')}</Button>
        </DefaultPage>
    );
}

