import React, {useEffect, useMemo, useRef} from 'react';
import {BiCart, BiMinus, BiPlus} from 'react-icons/bi';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/useTypedSelector';
import {addToCartMultiple, patchCartItem} from '../../../infrastructure/slices/cartSlice';
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import {OrderItemFormatted} from "../../../domain/models/cartApiResponseModel";

type DescribableFunction = {
    (event: React.MouseEvent): void;
};

const CartButton = (props: {variant: string, handleAddToCart: DescribableFunction, disabled?: boolean}) => {
    const {variant, handleAddToCart, disabled = false} = props;

    return <Button className={'btn-cart'} variant={'success'} onClick={handleAddToCart} disabled={disabled}>
        {(!['card', 'cart'].includes(variant)) && <BiPlus size={'25px'} style={{marginRight: '8px'}} />}
        <BiCart size={'25px'} />
    </Button>
}

export function AddToCart(props: { variationId: number, quantity?: number, showBtn?: boolean, variant?: 'card' | 'product' | 'cart', disabled?: boolean, btnDisabled?: boolean, updateQuantity?: (q: number) => void, minQuantity?: number, onPriceRequest?: () => void }) {
    const {variationId, quantity: initialQuantity = 1, showBtn = true, variant = 'card', disabled = false, btnDisabled = false, updateQuantity = null, minQuantity = 1, onPriceRequest = null} = props;
    const {t} = useTranslation('common');
    const inputRef = useRef(null);
    const dispatch = useAppDispatch();
    const {cart} = useAppSelector((state) => state.cart);

    const cartItem = cart && 'items' in cart && cart.items.find(item => item.variation_id === variationId) as OrderItemFormatted;
    const inCart = Boolean(cartItem);
    const autoUpdate = variant === 'cart';
    const background = variant === 'card' ? 'dark' : 'secondary';

    const quantity = useMemo(() => inCart && cartItem && autoUpdate ? parseInt(`${cartItem?.quantity ?? 1}`) : initialQuantity, [cartItem, initialQuantity]);

    useEffect(() => {
        const input = inputRef.current as unknown as HTMLInputElement;
        if (!input) return;
        const current = parseInt(input.value);
        if (current !== quantity) {
            input.value = `${quantity}`;
        }
    }, [quantity]);

    const handleIncrement = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (autoUpdate) {
            await dispatch(addToCartMultiple({map: [{quantity: 1, id: variationId}]}));
        }
        else {
            const input = inputRef.current as unknown as HTMLInputElement;
            if (!input) return;
            const current = parseInt(input.value);
            input.value = `${current + 1}`;
            if (updateQuantity) {
              updateQuantity(current + 1);
            }
        }
    };

    const handleDecrement = async (e: React.MouseEvent) => {
        e.stopPropagation();
        const input = inputRef.current as unknown as HTMLInputElement;
        if (!input) return;
        const current = parseInt(input.value);
        if (quantity > 1 && autoUpdate) {
            await dispatch(addToCartMultiple({map: [{id: variationId, quantity: -1}]}));
        }
        else if (current > 1) {
            input.value = `${current - 1}`;
            if (updateQuantity) {
              updateQuantity(current - 1);
            }
        }
    };

    const handleQuantityChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newQuantity = parseInt(e.target.value, 10);
        const validQuantity = isNaN(newQuantity) ? 1 : newQuantity;

        if (updateQuantity) {
          updateQuantity(validQuantity);
        }
        if (cartItem && cartItem?.order?.item && autoUpdate && quantity > 0) {
            await dispatch(patchCartItem({
                itemId: cartItem.order.item as unknown as number,
                cartId: cartItem.order.id as unknown as number,
                quantity: validQuantity
            }));
        }
    };

    const handleAddToCart = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (variationId) {
            const input = inputRef.current as unknown as HTMLInputElement;
            if (!input) return;
            const current = parseInt(input.value);
            const amt = autoUpdate ? quantity : current;
            if (amt > 0) {
                if (minQuantity > amt) {
                  if (onPriceRequest) {
                    onPriceRequest();
                  }
                }
                else {
                  dispatch(addToCartMultiple({map: [{quantity: amt, id: variationId}]}));
                  toast.success(t('Added to you cart'));
                }
            }
            if (!autoUpdate) {
                input.value = '1';
            }
        }
    };

    return (
        <>
            <InputGroup className={'quantity-input-group flex-grow'}>
                <Button variant={background} className={'decrement'} onClick={handleDecrement} type={'button'} disabled={disabled}>
                    <BiMinus size={'20px'} />
                </Button>
                <Form.Control className={'bg-' + background + ' cart-qty flex-grow'} defaultValue={quantity} onChange={handleQuantityChange}
                              ref={inputRef} readOnly={disabled} min={0} />
                <Button variant={background} className={'increment'} onClick={handleIncrement} type={'button'} disabled={disabled}>
                    <BiPlus size={'20px'} />
                </Button>
            </InputGroup>
            {showBtn && <CartButton variant={variant} handleAddToCart={handleAddToCart} disabled={btnDisabled} />}
        </>
    );
}
