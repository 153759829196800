import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios from "axios";
import {CheckoutCompletedSliceModel, CompletedOrder} from "./checkoutCompletedSliceModel";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

const initialState: CheckoutCompletedSliceModel = {
    loading: false,
    loaded: false,
    error: null,
    order: {} as CompletedOrder,
};

export const getOrderInfo = createAsyncThunk(
    "checkoutCompletedSlice/getOrderInfo",
    async (props: { order: string }, thunkAPI) => {
        const {order} = props;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = `${API_BASE_URL}${formatLang}/jsonapi/commerce_order/default/${order}?jsonapi_include=1&include=uid,order_items,order_items.purchased_entity,order_items.product_images`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status > 199 && response.status < 300) {
                return response.data.data;
            }

            return thunkAPI.rejectWithValue('Something went wrong');
        }
        catch (e) {
            return thunkAPI.rejectWithValue('Something went wrong');
        }
    }
)

const checkoutCompletedSlice = createSlice({
    name: 'checkoutCompletedSlice',
    initialState,
    reducers: {
        // Reducers can be added here if needed in the future

    },
    extraReducers: (builder) => {
        builder.addCase(getOrderInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrderInfo.rejected, (state, action) => {
            state.loaded = true;
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(getOrderInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.order = action.payload;
            state.loaded = true;
        });
    }
});


export default checkoutCompletedSlice.reducer;
