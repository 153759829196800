import {Badge, Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AddToCart} from "../../../../cart/modules/pages/cart/addToCart";
import {ProductModel} from "../../../domain/models/productModel";
import {FavoriteBtn} from "../../../../productFavorites/modules/commons/favoriteBtn";
import React, {useState} from "react";
import default150 from "../../commons/images/product/default-150.png";
import {formatNum} from "../../../../general/features/util";
import {useAppSelector} from "../../../../../hooks/useTypedSelector";
import {useTranslation} from "react-i18next";
import {ProductQuoteDataInfoModal} from "../../features/product/ProductQuoteDataInfoModal";

export const onProdImgError = (e: React.SyntheticEvent) => {
    (e.target as HTMLImageElement).src = default150;
}

export function ProductCard(props: { product: ProductModel, onPriceRequest: (productId: string, name: string, quantity: number, minQuantity?: number) => void }) {
    const {product, onPriceRequest} = props;
    const {lang} = useParams();
    const {t} = useTranslation('common');

    const navigation = useNavigate();
    const thumbnail = product.images.length > 0 ? product.images[0].thumbnail : default150;

    const {currentUser} = useAppSelector((state) => state.currentUser);

    const gotoProduct = (event: {preventDefault: () => void}) => {
        event.preventDefault();

        navigation(`/${lang}/assortment/${product.id}`)
    };

    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const addQuantity = (quantity: number) => {
        setSelectedQuantity(quantity);
    };

    const isWholesale = ['GROOTH', 'GH-INT'].includes(`${currentUser?.data.active_relation?.pricelist}`)

    const disabled = (product?.scaled_prices?.length ?? 0) === 0 && isWholesale;

    const minQuantity = (product?.scaled_prices?.length ?? 0) > 0 ? Math.min(...product.scaled_prices.map(price => price.quantity ?? 1)) : 1;

    const handlePriceReq = () => {
      onPriceRequest(product.drupal_internal__product_id, product.name, selectedQuantity, minQuantity);
    }

    return (
        <Card className={'product-card'}>
                <div style={{position: 'relative', paddingBottom: '100%', width: '100%'}}>
                    <a onClick={gotoProduct} href={`/${lang}/assortment/${product.id}`}>
                        <Card.Img
                            variant={'top'}
                            src={thumbnail}
                            onError={onProdImgError}
                            draggable={false}
                            loading={'lazy'}
                            decoding={'async'}
                            style={{
                                display: 'block',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </a>

                    <div style={{display: 'block', position: 'absolute', right: 5, top: 5}}>
                        <ProductQuoteDataInfoModal product={product} />
                    </div>
                </div>
            <Card.Body className={"d-flex flex-column justify-content-between"}>
                <a onClick={gotoProduct} href={`/${lang}/assortment/${product.id}`}>
                    <Card.Title>{product.name ?? ''}</Card.Title>
                    <Card.Text className={'article-number'}>
                        Art. nr.: {product.sku ?? ''}
                    </Card.Text>
                </a>
                <div>
                    <div className={'product-price-badge'}>
                        {(product?.scaled_prices?.length ?? 0) > 0 ? product.scaled_prices.map((item, key) =>
                            <Badge key={key} bg={'primary'} onClick={() => addQuantity(item.quantity > 0 ? item.quantity : 1)}>
                                {formatNum(`${item.price ?? ''}`)}
                                {item.quantity > 0 && <small>{item.quantity} st.</small>}
                            </Badge>
                        ) : (
                          isWholesale ? (
                            <Badge bg={'primary'} className={'cursor-pointer'} onClick={handlePriceReq}>{t('Price on request')}</Badge>
                            ) : (
                            <Badge bg={'primary'}>{product.price ?? ''}</Badge>
                          )
                        )}
                    </div>
                    <div className={'add-to-cart justify-content-between d-flex'}>
                        <div className={'d-flex flex-row flex-grow'}>
                            <div className={'d-flex'}>
                                <AddToCart variationId={product.variations.drupal_internal__variation_id}
                                           variant={'card'}
                                           quantity={selectedQuantity}
                                           btnDisabled={disabled}
                                           minQuantity={minQuantity}
                                           onPriceRequest={() => handlePriceReq()}
                                           updateQuantity={(q) => setSelectedQuantity(q)}
                                />
                            </div>
                        </div>
                        <div>
                            <FavoriteBtn size={'25px'} productId={product.id} variant={'card'}/>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
