import {useTranslation} from "react-i18next";
import {BaseAccountPage} from "../Features/baseAccountPage";
import {Row, Col, Button} from "react-bootstrap";
import React, {FormEvent, useEffect, useRef} from "react";
import {TextField} from "../../../../shared/Components/Input/TextField";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {getRelationInfo} from "../../../checkout/infrastructure/slices/checkoutSlice";
import {Loading} from "../../../general/features/loading";
import {changePassword} from "../../infrastructure/slices/accountSlice";
import {toast} from "react-toastify";

const MIN_LENGTH = 5;

export function AccountPage() {
    const {t} = useTranslation('common');

    const dispatch = useAppDispatch();
    const {relationData, relationLoaded, relationLoading} = useAppSelector(state => state.checkout);
    const {currentUser} = useAppSelector((state) => state.currentUser)
    const {validation} = useAppSelector((state) => state.account)


    useEffect(() => {
        if (!relationLoading) {
            dispatch(getRelationInfo());
        }
    }, []);

    const formRef = useRef(null);
    const newPwd = useRef(null);
    const newPwdRepeat = useRef(null);
    const msg = useRef(null);
    const btn = useRef(null);

    const handlePwdChange = () => {
        const pass = newPwd.current as unknown as HTMLInputElement;
        const repeat = newPwdRepeat.current as unknown as HTMLInputElement;
        if (!pass || !repeat || pass.value.length < 1 || repeat.value.length < 1) return;

        const msgEl = msg.current as unknown as HTMLInputElement;
        const btnEl = btn.current as unknown as HTMLInputElement;
        if (!msgEl || !btnEl) return;

        const match = pass.value === repeat.value;

        msgEl.innerText = t(match ? 'Passwords match!' : "Passwords don't match.");
        btnEl.disabled = !match;
    }

    const submitPassResetForm = (event: FormEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const form = formRef.current;
        if (!form) return;

        const formData = new FormData(form);

        const formDataObj = {
            current: formData.get('current') as string,
            new: formData.get('new') as string,
            repeat: formData.get('repeat') as string,
        };

        dispatch(changePassword(formDataObj)).then(action => {
            const data = action.payload as {status: 0|1};

            if (data.status === 1) {
                toast.success(
                    t('Successfully changed password')
                );
            }
            else {
                toast.error(
                    t('Not all values were correct.')
                );
            }
        });

        return false;
    }

    const Entry = (props: {label: string, value: string}) =>  <div className={'my-2'}>
        <b>{props.label}:</b> <br/>
        <span>{props.value}</span>
    </div>

    return (
        <BaseAccountPage className={'account'} pageTitle={t('My account')}>
            <Row>
                <Col xs={12} className={'mb-4'}>
                    <h3>{t('My details')}</h3>
                </Col>
                <Col xs={12} className={'acct-block p-rel mh-300'}>
                    <h4>{t('Account details')}</h4>

                    {relationLoaded ? <Row>
                        <Col xs={12}>
                            <Entry label={t('Company')} value={relationData.name ?? ''} />
                        </Col>
                        <Col xs={12}>
                            <Entry label={t('Name')} value={`${currentUser?.data?.firstname ?? ''} ${currentUser?.data?.surname_prefix ? `${currentUser?.data?.surname_prefix} ` : ''}${currentUser?.data?.surname ?? ''}`} />
                        </Col>
                        <Col xs={12}>
                            <Entry label={t('City')} value={relationData?.address?.locality ?? ''} />
                        </Col>
                        <Col xs={12}>
                            <Entry label={t('Email address')} value={relationData?.mail ?? ''} />
                        </Col>
                        <Col xs={12}>
                            <Entry label={t('Phone number')} value={relationData?.phone ?? ''} />
                        </Col>
                    </Row> : <Loading />}
                </Col>
                <Col xs={12} className={'acct-block p-rel mh-150'}>
                    <h4>{t('Invoice address')}</h4>
                    {relationLoaded ? <Row>
                    <Col xs={12}>
                        <Entry label={t('Address')} value={relationData.address?.address_line1 ?? ''} />
                    </Col>
                    <Col xs={12}>
                        <Entry label={t('Postal code')} value={`${relationData.address?.postal_code} ${relationData.address?.locality}, ${relationData.address?.country_code}`} />
                    </Col>
                </Row> : <Loading />}
                </Col>
                <Col xs={12} className={'acct-block'}>
                    <h4>{t('Password')}</h4>
                    <form onSubmit={submitPassResetForm} ref={formRef} key={validation.length}>
                        <TextField label={t('Current password')} inputProps={{
                            required: 'required',
                            type: 'password',
                            autoComplete: 'current-password',
                            name: 'current',
                        }} error={Boolean(validation.find(x => x.field === 'current'))}
                        helperText={(validation.find(x => x.field === 'current'))?.message ?? null}
                        />
                        <TextField label={t('New password')} inputProps={{
                            required: 'required',
                            minLength: MIN_LENGTH,
                            type: 'password',
                            ref: newPwd,
                            autoComplete: 'new-password',
                            name: 'new'
                        }} onChange={handlePwdChange} error={Boolean(validation.find(x => x.field === 'new'))}
                                   helperText={(validation.find(x => x.field === 'new'))?.message ?? null} />
                        <TextField label={t('Repeat new password')} inputProps={{
                            required: 'required',
                            minLength: MIN_LENGTH,
                            type: 'password',
                            ref: newPwdRepeat,
                            autoComplete: 'new-password',
                            name: 'repeat'
                        }} onChange={handlePwdChange} error={Boolean(validation.find(x => x.field === 'repeat'))}
                                   helperText={(validation.find(x => x.field === 'repeat'))?.message ?? null} />
                        <div className={'d-block mb-3'}>
                            <span ref={msg}></span>
                        </div>

                        <Button variant={'danger'} type={'submit'} ref={btn}>{t('Save password')}</Button>
                    </form>
                </Col>
            </Row>
        </BaseAccountPage>
    );
}