import React, {useState} from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

interface DateFieldProps {
    label: string,
    onChange?: (newValue: Value) => void,
    defaultValue?: Value,
    inputProps?: object,
    labelProps?: object,
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export function DateField(props: DateFieldProps) {
    const {label, labelProps, inputProps, onChange, defaultValue} = props;


    const [value, setValue] = useState<Value>(defaultValue ? defaultValue : new Date());

    const handleChange = (value: Value) => {
        setValue(value)
        if (onChange) {
            onChange(value);
        }
    }


    if (labelProps && 'className' in labelProps) {
        labelProps.className = `${labelProps.className} date`;
    }

    return <div className={'date'} {...labelProps}>
        {label}
        <DatePicker onChange={handleChange} value={value} format={"dd-MM-y"} locale={'nl-NL'} {...inputProps} />
    </div>
}