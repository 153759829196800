import {
    Button,
    Modal as BsModal,
    ModalBody as BsModalBody,
    ModalFooter as BsModalFooter,
    ModalHeader as BsModalHeader,
    ModalTitle
} from "react-bootstrap";
import {ModalBodyProps, ModalCombinedProps, ModalHeaderProps, ModalWrapperProps} from "./types";
import {t} from "i18next";
import {useState} from "react";

export function ModalWrapper({children, colorVariant = 'primary', ...props}: ModalWrapperProps) {
    return (
        <BsModal centered {...props} className={`corny-bakers-modal corny-bakers-modal-${colorVariant} ${props.className ?? ''}`}>
            {children}
        </BsModal>
    );
}

export function ModalHeader({title, ...props}: ModalHeaderProps) {
    return (
        <BsModalHeader {...props} className={`corny-bakers-modal-header ${props.className ?? ''}`}>
            <ModalTitle>{title}</ModalTitle>
        </BsModalHeader>
    );
}

export function ModalBody({children, ...props}: ModalBodyProps) {
    return (
        <BsModalBody {...props} className={`corny-bakers-modal-body ${props.className ?? ''}`}>
            {children}
        </BsModalBody>
    );
}

export function ModalFooter({children, ...props}: ModalBodyProps) {
    return (
        <BsModalFooter {...props} className={`corny-bakers-modal-footer ${props.className ?? ''}`}>
            {children}
        </BsModalFooter>
    );
}

export function ModalConfirm({children, colorVariant = 'primary', title, openBtnText, onConfirm}: ModalCombinedProps) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={colorVariant} onClick={handleShow}>{openBtnText}</Button>

            <ModalWrapper colorVariant={colorVariant} show={show} onHide={handleClose}>
                <BsModalHeader>{title}</BsModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button variant={'secondary'} onClick={handleClose}>{t('Cancel')}</Button>
                    <Button variant={'primary'} onClick={() => {
                        handleShow();
                        onConfirm?.();
                    }}>{t('Save')}</Button>
                </ModalFooter>
            </ModalWrapper>
        </>
    );
}

export function ModalInfo({children, colorVariant = 'primary', title, openBtnText}: ModalCombinedProps) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={colorVariant} onClick={handleShow}>{openBtnText}</Button>

            <ModalWrapper colorVariant={colorVariant} show={show} onHide={handleClose}>
                <BsModalHeader>{title}</BsModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
            </ModalWrapper>
        </>
    );
}