import React, {BaseSyntheticEvent} from 'react';

interface TextFieldProps {
    label: string,
    onChange?: (newValue: string) => void,
    inputProps?: object,
    labelProps?: object,
    error?: boolean,
    helperText?: string|null
}

export function TextField(props: TextFieldProps) {
    const {label, onChange, inputProps, labelProps, helperText, error} = props;

    const handleChange = (event: BaseSyntheticEvent) => {
        if (onChange) {
            onChange(event.target.value);
        }
    }

    if (labelProps && 'className' in labelProps) {
        labelProps.className = `${labelProps.className} text-field`;
    }

    return <label className={'text-field'} {...labelProps}>
        {label}
        <input type={'text'} onChange={handleChange} {...inputProps} />
        {helperText && <small className={error ? 'text-danger' : 'text-muted'} dangerouslySetInnerHTML={{__html: helperText}} />}
    </label>
}