import {createSlice} from "@reduxjs/toolkit";
import ResetPasswordDataSliceModel from "./resetPasswordDataSliceModel";

const initialState = {
    timestampState: null,
    hashState: null,
} as ResetPasswordDataSliceModel;

const resetPasswordDataSlice = createSlice({
    name: "resetPasswordData",
    initialState,
    reducers: {
        setTimestamp(state, action) {
            state.timestampState = action.payload;
            localStorage.setItem('timestampState', action.payload);

        },
        setHash(state, action) {
            state.hashState = action.payload;
            localStorage.setItem('hashState', action.payload);
        }
    },
});

export const {setTimestamp, setHash} = resetPasswordDataSlice.actions
export default resetPasswordDataSlice.reducer;