import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React from "react";
import {ProductModel} from "../../../domain/models/productModel";
import {useTranslation} from "react-i18next";

export function ProductTabs({product}: {product: ProductModel}) {
    const {t} = useTranslation('common');
    return (
        <div className={'product-tabs'}>
            <Tabs
                variant={'tabs'}
                defaultActiveKey={"description"}
                id={"uncontrolled-tab-example"}
            >
                <Tab eventKey={'description'} title={t('Description')}>
                    <div dangerouslySetInnerHTML={{__html: product?.description ?? ''}}></div>
                </Tab>
                <Tab eventKey={'specifications'} title={t('Specifications')}>
                <div dangerouslySetInnerHTML={{__html: product?.specifications ?? ''}}></div>
                </Tab>
            </Tabs>
        </div>
    );
}
