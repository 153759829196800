import {Accordion, Card} from "react-bootstrap";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
    useAppDispatch,
    useAppSelector
} from "../../../../../hooks/useTypedSelector";
import {Category} from "../../../../header/infrastructure/slices/menuSlice/menuSliceModel";
import {useNavigate} from "react-router-dom";
import CategoryService from "../../../../header/infrastructure/services/categoryService";
import {
    getCategories
} from "../../../../header/infrastructure/slices/menuSlice/menuSlice";
import {Loading} from "../../../../general/features/loading";

const ProductMenu = () => {
    const {t} = useTranslation('common');
    const {loading, mainCategories, categories} = useAppSelector(state => state.menu);
    const dispatch = useAppDispatch();

    const navigation = useNavigate();

    const categoryService = useMemo(() => new CategoryService(categories), [categories.length]);

    const getUrl = (id: number) => {
        return categoryService.softApplyCategoryFilter(id);
    }

    const goto = (id: number, event: { preventDefault: () => void; }) => {
        event.preventDefault();

        categoryService.applyCategoryFilter(navigation, id);
    }
    const parent = categoryService.getCurrentCategoryFilterId();

    useEffect(() => {
        if (!loading) {
            dispatch(getCategories());
        }
    }, []);

    return <>
        <Accordion className="d-md-none">
            <Card bg={'primary'} className={'product-categories'}>
                <Accordion.Header>
                    <h2 className={'mt-2'}>{t('Filter products')}</h2>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className={'p-rel mh-200'}>
                            {loading && <Loading />}
                            <ul>
                                {mainCategories.map((category: Category, delta: number) =>
                                    <a key={delta}
                                       onClick={(event) => goto(category.drupal_id, event)}
                                       className={parent === category.drupal_id ? 'active' : ''}
                                       href={getUrl(category.drupal_id)}
                                    >
                                        <li>{category.name}</li>
                                    </a>)}
                            </ul>
                        </div>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>
        <Card bg={'primary'} className={'product-categories d-none d-md-block'}>
            <Card.Body>
                <h2 className={'mt-2'}>{t('Product categories')}</h2>

                <div className={'p-rel mh-200'}>
                    {loading && <Loading />}
                    <ul>
                        {mainCategories.map((category: Category, delta: number) =>
                            <a key={delta}
                               onClick={(event) => goto(category.drupal_id, event)}
                               className={parent === category.drupal_id ? 'active' : ''}
                               href={getUrl(category.drupal_id)}
                            >
                                <li>{category.name}</li>
                            </a>)}
                    </ul>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default ProductMenu;