import logo from '../commons/images/logo.png'
import {useNavigate} from "react-router-dom";
import i18next from "i18next";

export function Logo () {
    const navigation = useNavigate();
    const lang = i18next.language;

    const gotoHome = (event: {preventDefault(): void}) => {
        event.preventDefault();
        navigation(`/${lang}/assortment`)
    }

    return (
        <div>
            <a href={`/${lang}/assortment`} onClick={event => gotoHome(event)}>
                <img loading="lazy" decoding="async" src={logo} alt={'Logo'} className={'img-fluid logo'} />
            </a>
        </div>
    );
}
