import { Nav } from "react-bootstrap";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useTypedSelector";
import { footerMenu } from "../../infrastructure/slices/footerSlice/footerSlice";
import { MenuLink } from "../../infrastructure/slices/footerSlice/footerSliceModel";

export type FooterNavigationProps = {
  label: string;
  menu: string;
  horizontal?: boolean;
};

export function FooterNavigation({ label, menu, horizontal = false }: FooterNavigationProps) {
  const { menus } = useAppSelector(state => state.link);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(footerMenu(menu));
  }, []);

  const menuData = menus.find(menuData => menuData.menu == menu) ?? {
    menu: menu,
    links: [] as MenuLink[]
  };

  return (
    <>
      <div style={{ paddingBottom: '20px', paddingTop: '40px', fontWeight: 'bold', paddingLeft: '14px' }}>
        {label}
      </div>
      
      <Nav variant={'tabs'} defaultActiveKey='' style={{display:'inline-block'}} className={horizontal ? 'd-flex align-items-center' : 'flex-column'}>
        {menuData.links.map((menuLink: MenuLink, index: number) => (
          <React.Fragment key={index}>
            {horizontal && index > -1 && <span className="mx-2">|</span>} 
            <Nav.Item>
              <Nav.Link
                className="footer-bg"
                style={horizontal ? {} : { display: 'inline-block' }}
                href={menuLink.url}
              >
                {menuLink.title}
              </Nav.Link>
            </Nav.Item>
          </React.Fragment>
        ))}
      </Nav>
    </>
  );
}
