import React from "react";
import { BiChevronDown } from "react-icons/bi";
import {RiAccountCircleLine} from "react-icons/ri";

export const Welcome = ({name}: { name: string }) => {
    return <>
        <div className={'header-icon-wrapper d-md-none'}>
            <span className={'header-icon'}><RiAccountCircleLine/></span>
        </div>
        <div className={'d-none d-md-block mx-3'}>
            <p>
                <strong>{name}</strong>
                <span className={'dropdown-icon'}><BiChevronDown/></span>
            </p>
        </div>
    </>;
}
