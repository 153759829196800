import React from "react";
import {BiHeart, BiSolidHeart} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {addProductFavorite, deleteProductFavorite} from "../../infrastructure/slices/productFavoriteSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {Button} from "react-bootstrap";

type FavoriteBtnProps = {
    variant?: string;
    productId?: string;
    size?: string;
};

export function FavoriteBtn(props: FavoriteBtnProps) {
    const {
        variant,
        productId,
        size = '',
    } = props;

    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();

    const {favorites, loaded, loading} = useAppSelector(state => state.productFavorite);

    const isFavorite = loaded && productId && !!(favorites.find(item => item.product === productId));

    const handleProductFavorite = async (e: React.MouseEvent) => {
        e.stopPropagation();

        if (productId && loaded) {
            if (!isFavorite) {
                await dispatch(addProductFavorite(productId));

                toast.success(t('Added to your order list'));
            } else {
                await dispatch(deleteProductFavorite(productId));

                toast.error(t('Removed from your order list'));
            }
        }
    };

    const background = variant === 'card' || variant === 'cart' ? 'dark' : 'secondary';

    return (
        <div>
            <Button className={'btn-favorite'} variant={background} onClick={handleProductFavorite}
                    disabled={loading}>
                {!isFavorite ? (
                    <BiHeart className={'header-icon link'} onClick={handleProductFavorite}
                             size={size}/>
                ) : (
                    <BiSolidHeart fill={'#B11E17'} className={'header-icon link'} size={size}
                                  onClick={handleProductFavorite}/>
                )}
            </Button>
        </div>
    );
}
