import React from "react";
import {Spinner} from "react-bootstrap";

export function Loading() {
    return (
        <div className={'spinner-wrapper mh-50'}>
            <Spinner animation="border" role="status"></Spinner>
        </div>
    );
}
