import React, {useState} from "react";
import {Button, ModalHeader as BsModalHeader} from "react-bootstrap";
import {ModalBody, ModalWrapper} from "../../../../../shared/Components/modal/Modal";
import {BsFillInfoCircleFill} from "react-icons/bs";
import {ProductModel, QuoteProductData} from "../../../domain/models/productModel";
import {t} from "i18next";

type QuoteProductDataProps = {
    quote: QuoteProductData;
    isMultiple: boolean;
    isLastItem: boolean;
}

export function QuoteProductDataView({quote, isMultiple, isLastItem}: QuoteProductDataProps) {
    return (
        <>
            <h5>{t('Quote {{id}} - {{label}}', {
                id: quote.id,
                label: quote.label
            })}</h5>

            <p>
                {t('Note! There is a better price available for this item. In fact, there is another quote with price agreements for this item, among others, open for you to accept.')}
            </p>

            <div>
                {t("For this article, these prices are on the quotation:")}

                {quote.productData.map((productData, index) => {
                    return (
                        <div key={`${quote.url}-product-data-${index}`}>
                            {productData.quote_price_formatted}
                            {productData.quantity && (<>
                                &#xa0; - {productData.quantity} {productData.unit_label}
                            </>)}
                            <br />
                        </div>
                    )
                })}
            </div>

            <div className={"row"}>
                <div className={"col"}></div>
                <a href={quote.url} className={"btn btn-light col-4 me-2"} target={"_blank"} rel={"noreferrer"}>
                    {t('Go to quote')}
                </a>
            </div>

            {isMultiple && !isLastItem && <hr />}
        </>
    );
}

type OverviewProps = {
    product: ProductModel;
}


export function ProductQuoteDataInfoModal({product}: OverviewProps) {
    const [show, setShow] = useState(false);

    if (!product.quote_product_data || product.quote_product_data.length < 1) {
        return null;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isMultiple = product.quote_product_data && product.quote_product_data.length > 1;
    const lastIndex = product.quote_product_data ? product.quote_product_data.length - 1 : 0;

    return (
        <>
            <BsFillInfoCircleFill className={"icon icon-orange"} size={30} onClick={handleShow}/>

            <ModalWrapper show={show} onHide={handleClose}>
                <BsModalHeader>{t('Better price for {{product}}', {
                    product: product.name
                })}</BsModalHeader>
                <ModalBody>
                    {product.quote_product_data?.map((data, index) => {
                        return <QuoteProductDataView
                            key={`${data.url}-${index}`}
                            quote={data}
                            isMultiple={isMultiple}
                            isLastItem={index === lastIndex}
                        />;
                    })}
                </ModalBody>
            </ModalWrapper>
        </>
    );
}

export function ProductQuoteDataInfoBtnModal({product}: OverviewProps) {
    const [show, setShow] = useState(false);

    if (!product.quote_product_data || product.quote_product_data.length < 1) {
        return null;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isMultiple = product.quote_product_data && product.quote_product_data.length > 1;
    const lastIndex = product.quote_product_data ? product.quote_product_data.length - 1 : 0;

    return (
        <>
            <Button variant={"secondary"} className={"btn-favorite"}>
                <BsFillInfoCircleFill className={"icon icon-orange"} size={20} onClick={handleShow}/>
            </Button>

            <ModalWrapper show={show} onHide={handleClose}>
                <BsModalHeader>{t('Better price for {{product}}', {
                    product: product.name
                })}</BsModalHeader>
                <ModalBody>
                    {product.quote_product_data?.map((data, index) => {
                        return <QuoteProductDataView
                            key={`${data.url}-${index}`}
                            quote={data}
                            isMultiple={isMultiple}
                            isLastItem={index === lastIndex}
                        />;
                    })}
                </ModalBody>
            </ModalWrapper>
        </>
    );
}