import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const paramName = 'search';

export function HeaderSearch() {
    const { t } = useTranslation('common');
    const urlParams = new URLSearchParams(window.location.search);

    const [searchText, setSearchText] = useState(`${urlParams.get(paramName) ?? ''}`);
    const navigation = useNavigate();
    const location = useLocation();
    const {lang} = useParams();

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);

      setSearchText(urlParams.get(paramName) ?? '');
    }, [location.pathname, location.search]);

    const handleFormSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete(paramName);
        urlParams.set(paramName, searchText);

        navigation(`/${lang}/assortment?${urlParams.toString()}`)
    };

    const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchText(event.target.value);
    };

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group>
                    <Form.Control
                        className={"search"}
                        type="text"
                        placeholder={t('Search on productname or articlenumber') || ''}
                        value={searchText}
                        onChange={handleInputChange}
                    />
                </Form.Group>
            </Form>
        </>
    );
}
