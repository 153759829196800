import {useTranslation} from "react-i18next";
import {Button, Spinner} from "react-bootstrap";
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from "../../../../../shared/Components/modal/Modal";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useTypedSelector";
import {requestProductPrice} from "../../../infrastructure/slices/productSlice/productSlice";

export function PriceRequestModal(props: {productId: string, productName: string, onClose: () => void, open?: boolean, count?: number, minCount?: number }) {
  const {productId, productName, onClose, open = false, count = 0, minCount = 1} = props;

  const dispatch = useAppDispatch();

  const {t} = useTranslation('common');

  const {requestLoading} = useAppSelector(state => state.product)

  const handleRequest = () => {
    dispatch(requestProductPrice({id: productId, quantity: minCount > 1 ? count : 1}))
      .then(() => {
        onClose();
      })
  }

  return (
    <>
      <ModalWrapper show={open} onHide={onClose}>
        <ModalHeader title={t('Request a price')}></ModalHeader>
        <ModalBody>
          {minCount < 2 ? <>
            {t('Are you sure you want to request a price for ')}{'"'}{productName}{'"?'}<br />
            {t('By doing this you will send an email to our sales department. They will contact you.')}
          </> : <>
            {t('There is no price agreement for this product with the chosen amount (@count)').replace('@count', `${count}`)}<br />
            {t('Do you want to request a price for this product for the quantity of @count?').replace('@count', `${count}`)}
          </>}
        </ModalBody>
        <ModalFooter className="price-request-modal-footer">
          <Button variant={'light'} onClick={handleRequest} disabled={requestLoading}>
            {requestLoading && <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                &nbsp;
            </>}
            {t('Request')}
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </>
  );
}
