import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Logo} from "../../../page/features/logo";
import {HeaderSearch} from "./headerSearch";
import {Welcome} from "./welcome";
import {HeaderButtons} from "./headerButtons";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {getCart} from "../../../cart/infrastructure/slices/cartSlice";
import {useNavigate, useParams} from "react-router-dom";
import {MenuItems} from "../../../content/modules/pages/AccountMenu";
import LangSelect from "./langSelect";
import i18next from "i18next";

export function Header() {
    const {t} = useTranslation('common');

    const navigation = useNavigate();
    const {lang} = useParams();

    const {currentUser} = useAppSelector(state => state.currentUser)
    const { loading} = useAppSelector(state => state.cart)

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!loading) {
            dispatch(getCart());
        }
    }, []);

    const dropdownTitleContent = currentUser ? (
        <Welcome name={currentUser.name ?? t('Guest')} />
    ) : t('Guest');

    const items = MenuItems(dispatch, t, lang ?? i18next.language, currentUser?.data.is_masquerading ?? false);

    return (
        <div className={'header'}>
            <Container>
                <Row>
                    <Col
                        xs={{order: 1, span: 4}}
                        sm={{order: 1, span: 4}}
                        md={{order: 1, span: 3}}>
                        <Logo/>
                    </Col>
                    <Col
                        xs={{order: 3, span: 12}}
                        sm={{order: 3, span: 12}}
                        md={{order: 2, span: 5}}
                        className={'d-flex justify-content-between flex-column pt-4'}>
                        <HeaderSearch/>
                    </Col>
                    <Col
                        xs={{order: 2, span: 8}}
                        sm={{order: 2, span: 8}}
                        md={{order: 3, span: 4}}
                        className={'pt-4 pb-2'}>
                        <Row className={'justify-content-between flex-column h-100'}>
                            <Col xs={12} className={'header-account d-flex justify-content-end align-items-baseline'}>
                                <NavDropdown
                                    id="menu-dropdown-corny-bakers"
                                    title={dropdownTitleContent}
                                    className="custom-dropdown"
                                    align="end"
                                    >
                                    {items.map((item, delta) => <NavDropdown.Item key={delta} onClick={() => {
                                        if (item.onClick && typeof item.onClick === 'function') {
                                            item.onClick();
                                        }
                                        else if (item.href) {
                                            if (item.external) {
                                                // Open external links in a new tab
                                                window.open(item.href, '_blank');
                                                return;
                                            }

                                            navigation(item.href);
                                        }
                                    }}>
                                        <>
                                            {item.icon}
                                            {item.title}
                                        </>
                                    </NavDropdown.Item>)}
                                </NavDropdown>
                                <HeaderButtons/>
                            </Col>
                            <Col xs={12} className={'d-flex justify-content-end'}>
                                <LangSelect />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
