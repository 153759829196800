import React, {useEffect, useState} from "react";
import {PageTitle} from "../../../page/features/PageTitle";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {getProduct} from "../../infrastructure/slices/productSlice/productSlice";
import {Badge, Col, Row} from "react-bootstrap";
import {DefaultPage} from "../../../page/features/default";
import {ProductTabs} from "./product/productTabs";
import {defaultImages, ProductImage} from "./product/productImage";
import {AddToCart} from "../../../cart/modules/pages/cart/addToCart";
import {useParams} from "react-router-dom";
import {FavoriteBtn} from "../../../productFavorites/modules/commons/favoriteBtn";
import {useTranslation} from "react-i18next";
import {Loading} from "../../../general/features/loading";
import NotFoundPage from "../../../content/modules/pages/404Page";
import {PriceRequestModal} from "./product/priceRequestModal";
import {ProductQuoteDataInfoBtnModal} from "../features/product/ProductQuoteDataInfoModal";

export function ProductPage() {
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {t} = useTranslation('common');

    useEffect(() => {
        if (id) {
            dispatch(getProduct(id));
        }
    }, [id]);

    const {product, loading, loaded} = useAppSelector((state) => state.product);

    const {currentUser} = useAppSelector((state) => state.currentUser);

    const formatNum = (number: string) => {
        return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(
            parseFloat(number),
        );
    }
    const replacements: { [index: string]: string } = {};
    if (!loading) {
        replacements[id as string] = product?.name ?? t('Product');
    }

    const isWholesale = ['GROOTH', 'GH-INT'].includes(`${currentUser?.data.active_relation?.pricelist}`);

    const disabled = (product?.scaled_prices?.length ?? 0) === 0 && isWholesale;

    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const minQuantity = (product?.scaled_prices?.length ?? 0) > 0 ? Math.min(...product.scaled_prices.map(price => price.quantity ?? 1)) : 1;

    return (
        <DefaultPage className={"product-page mt-5"} replacements={replacements}>
            {(loaded && Object.keys(product).length === 0) ? <NotFoundPage/> : (
                <div className={'mh-500 p-rel'}>
                    <Row>
                        <Col
                            xs={{order: 1, span: 12}}
                            sm={{order: 0, span: 6}}
                            md={{order: 0, span: 6}}
                            className={'p-rel mh-500'}
                        >
                            {loading ? <><Loading/><ProductImage images={defaultImages}/></> :
                                <ProductImage images={product?.images ?? []}/>}
                        </Col>
                        <Col
                            xs={{order: 0, span: 12}}
                            sm={{order: 1, span: 6}}
                            md={{order: 1, span: 6}}
                        >
                            <PageTitle title={product?.name ?? ""}/>
                            <div className={'p-rel mh-150'}>
                                {loading ? <Loading/> : <>
                                    <div className={'product-price-badge'}>
                                        {(product?.scaled_prices?.length ?? 0) > 0 ? product.scaled_prices.map((item, key) =>
                                            <Badge key={key} bg={'primary'} onClick={() => setQuantity(item.quantity > 0 ? item.quantity : 1)}>
                                                {formatNum(`${item.price ?? ''}`)}
                                                {item.quantity > 0 && <small>{item.quantity} st.</small>}
                                            </Badge>
                                        ) : (
                                          isWholesale ? (
                                              <Badge bg={'primary'} className={'cursor-pointer'} onClick={() => setOpen(true)}>{t('Price on request')}</Badge>
                                          ) : (
                                              <Badge bg={'primary'}>{product.price ?? ''}</Badge>
                                          )
                                        )}
                                    </div>
                                    <div className={'d-flex gap-10'}>
                                        <div className={"d-inline-flex"}>
                                            <AddToCart variationId={product?.variations?.drupal_internal__variation_id} minQuantity={minQuantity} onPriceRequest={() => setOpen(true)}
                                                       variant={'product'} btnDisabled={disabled} quantity={quantity} updateQuantity={q => setQuantity(q)}/>
                                        </div>
                                        <FavoriteBtn variant={'product'} size={'25px'} productId={product.id}/>
                                        <ProductQuoteDataInfoBtnModal product={product} />
                                    </div>
                                </>}
                            </div>
                        </Col>
                    </Row>
                    <Row className={"mt-5"}>
                        <Col className={'p-rel mh-50'}>
                            {loading ? <Loading/> : <ProductTabs product={product}/>}
                        </Col>
                    </Row>
                    <PriceRequestModal productName={product?.name ?? t('Product')} open={open} onClose={() => setOpen(false)} count={quantity} productId={product.drupal_internal__product_id} minCount={minQuantity} />
                </div>
            )}
        </DefaultPage>
    );
}
