import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../hooks/useTypedSelector";
import {Link, useNavigate, useParams} from "react-router-dom";
import {setHash, setTimestamp} from "../../infrastructure/slices/resetPasswordDataSlice/resetPasswordDataSlice";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios from "axios";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import i18next from "i18next";

export function PasswordResetForm() {
    const {t} = useTranslation('common');
    const { user, timestamp, hash, lang } = useParams<{
        user: string;
        timestamp?: string;
        hash?: string;
        lang?: string;
    }>();

    const navigation = useNavigate();

    const dispatch = useAppDispatch();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isPasswordSame, setIsPasswordSame] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        if (hash) {
            dispatch(setHash(hash));
            dispatch(setTimestamp(timestamp));
            navigation(`${lang ? `/${lang}` : ''}/user/password/reset/${user}`)
        }
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setHasErrors(false)

        if(password !== passwordConfirm) {
            setIsPasswordSame(false);
            return
        }

        setIsPasswordSame(true);

        const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

        const data = {
            pass: password,
            passConfirm: passwordConfirm,
        }
        const timestampState = localStorage.getItem('timestampState');
        const hashState = localStorage.getItem('hashState');

        const resetPasswordUrl = `${API_BASE_URL}/api/reset-password/${user}/${timestampState}/${hashState}`;

        axios.post(resetPasswordUrl, data).then((response) => {
            if(response.data.status) {
                navigation(`/`);
            } else {
                setHasErrors(true);
            }
        }).catch(err => {
            console.log(err);
            setHasErrors(true);
        })
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row className={'login-card p-2 py-4 mb-3'}>
                    <h4 className={'py-3'}>{t('Reset password')}</h4>
                    <p className={'mt-0'}>{t('This login can only be used once')}</p>
                    <Form.Group className={'mb-3'} controlId={'loginForm.ControlPassword'}>
                        <Form.Label className={'m-0'}>{t('Password') || ''}</Form.Label>
                        <Form.Control
                            type={'password'}
                            required
                            onChange={(event) => setPassword(event.target.value)}
                            placeholder={t('Enter new password')}
                        />
                    </Form.Group>
                    <Form.Group className={'mb-3'} controlId={'loginForm.ControlPassword'}>
                        <Form.Label className={'m-0'}>{t('Password confirm') || ''}</Form.Label>
                        <Form.Control
                            type={'password'}
                            required
                            onChange={(event) => setPasswordConfirm(event.target.value)}
                            placeholder={t('Re-enter new password')}
                        />
                    </Form.Group>
                    { !isPasswordSame && (
                        <div className={'frontend-form-control'}>
                            {t('The passwords do not match')}
                        </div>
                    )}
                    { hasErrors && (
                        <div>
                            <Alert key={'danger'} variant={'danger'}>
                                {t('Something went wrong with resetting password, try again with a new link from the "Forgotten password" page.')}
                            </Alert>
                        </div>
                    )}
                </Row>
                <Row xs={'auto'} className={'align-items-center'}>
                    { hasErrors ? (
                        <Col>
                            <Link to={`/${i18next.language}/user/login`}>{t('Back to login')}</Link>
                        </Col>
                    ) : (
                        <Col className={'ps-0'}>
                            <Button variant={'cb-red'} type={'submit'}>
                                {t('Login')}
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form>
        </>
    );
}
