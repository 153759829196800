import React, { useEffect } from 'react';
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const {t} = useTranslation('common');
    useEffect(() => {
      document.body.classList.add("not-found");

      return () => {
      document.body.classList.remove("not-found");
      }
    },[]);

    return (
      <div className="not-found-page">
          <div className="content">
          <h1 className='title404'>404</h1>
          <p>{t('Oops! It seems our tacos wandered off the menu!')}</p>
          <p className='headBack404'>{t('Head back to the homepage')}</p> 
          <Link to="/" className="button">{t('Go home')}</Link>
        </div>
      </div>
    );
  };
  
  export default NotFoundPage;