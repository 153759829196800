export enum PageType {
    Page,
    NotFound,
}

export interface ContentSliceModel {
    content: MenuLinkData;
    loading: boolean;
    loaded: boolean;
}

export interface MenuLinkContent {
    individual:string;
}

export interface MenuLinkData {
    title: string;
    processed: string;
    type: PageType;
}