import React, {useEffect} from "react";
import {PageTitle} from "../../../page/features/PageTitle";
import {DefaultPage} from "../../../page/features/default";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {getNodeItems} from "../../infrastructure/contentSlice/contentSlice";
import { Loading } from "../../../general/features/loading";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import { PageType } from "../../infrastructure/contentSlice/contentSliceModel";
import NotFoundPage from "./404Page";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export function ContentPage() {
    const dispatch = useAppDispatch();
    const {pathname} = window.location

    useEffect(() => {
    if (pathname) {
        dispatch(getNodeItems(pathname));
    }
 }, [pathname]);

    const {content, loading} = useAppSelector((state) => state.content);

    const rewriteContent = (content: string) => {
        const doc = document.createElement('div');
        doc.innerHTML = content;
        const images = doc.getElementsByTagName('img');

        for (const image of images) {
            image.src = API_BASE_URL + image.getAttribute("src");
        }

        return doc.innerHTML;
    }

    return (
        <DefaultPage className={"mt-5"} showBreadCrumbs={false}>
            {loading ? <Loading /> : (
            <>
                {content.type == PageType.Page ? 
                <>
                    <div style={{ paddingTop: '20px' }}>
                        <PageTitle title={content.title} />
                    </div>
                    <div style={{ paddingBottom: '20px', paddingTop:'20px' }} dangerouslySetInnerHTML={{ __html: rewriteContent(content.processed) }} />
                </>
                : <NotFoundPage />
                }
                </>
            )}
        </DefaultPage>
    );
}